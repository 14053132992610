import React, { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/Modal";
import { ModalFooter } from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import RadioButton from "../../../components/Radio button/RadioButton";
import { SearchMember } from "./SearchMember";
import Icons from "../../../components/Icons/Icon";
import { Dropdown } from "../../../components/DropDown/DropDown";
import Textarea from "../../../components/TextArea/Textarea";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  checkUniquenessProjectAsync,
  getProjectByIdAsync,
  getProjectsAsync,
  updateProjectAsync,
} from "../../../services/reducers/adminReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import * as Yup from "yup";
import { getUsersAsync } from "../../../services/reducers/appReducer";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import EchopulseLoader from "../../loader/EchopulseLoader";

type Props = {
  onClose: () => void;
  projectId: string;
  projectList: () => void;
  setEditProject: React.Dispatch<React.SetStateAction<boolean>>;
  userList: any;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedMember?: any;
  selectedMember?: any;
};
type Values = {
  key: string;
  name: string;
  externalId: string;
  description: string;
  privacy: number;
  taskManagement: string;
  teamMembers: { memberId: string }[];
  projectType: number;
  platform: number;
};
const ProjectSchema = Yup.object().shape({
  name: Yup.string().required("Project name is Required"),
  key: Yup.string().required("Required"),
  description: Yup.string().required("Description is Required"),
});
const EditProjectModal: React.FC<Props> = ({
  onClose,
  projectId,
  projectList,
  setEditProject,
  userList,
  searchString,
  setSearchString,
  setSelectedMember,
  selectedMember,
}) => {
  const dispatch = useAppDispatch();
  const [projectData, setProjectData] = useState<any>({});
  const [isUniqueTitle, setIsUniqueTitle] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userLists, setUserLists] = useState<any>(null);

  const projectTypeOpt = [
    {
      label: "Internal Project",
      value: 0,
    },
    {
      label: "Client Project",
      value: 1,
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (projectId) {
      dispatch(getProjectsAsync(projectId)).then((res) => {
        if (res.payload) {
          setProjectData(res.payload);
          setLoading(false);
        }
      });
    }
  }, []);

  const validateTitle = async (title: string) => {
    if (title.trim() !== "") {
      const jsonData = {
        propertyName: "name",
        checkValue: title,
      };
      const currentTitle = projectData?.name || "";
      if (title !== currentTitle) {
        await dispatch(checkUniquenessProjectAsync(jsonData)).then((res) => {
          setIsUniqueTitle(res?.payload ? true : false);
          setTitleFocused(false);
        });
      }
      // await dispatch(checkUniquenessProjectAsync(jsonData)).then((res) => {
      //   setIsUniqueTitle(res?.payload ? true : false);
      //   setTitleFocused(false);
      // });
    }
  };
  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const search = () => {
    const request = {
      page: 1,
      pageSize: 2000,
    };
    dispatch(getUsersAsync(request)).then((res) => {
      const activeUsers = res?.payload?.users?.filter((user: any) => user.status === "Active");
      setUserLists(activeUsers);
      // setUserLists(res?.payload?.users);
    });
  };

  useEffect(() => {
    search();
  }, []);

  const removeMember = (memberIdToRemove: string) => {
    setSelectedMember((prevMembers: any) =>
      prevMembers.filter(
        (member: any) => member.identityId !== memberIdToRemove
      )
    );
  };

  const toggleShowAllMembers = () => {
    setShowAll(!showAll);
  };

  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <Formik
        enableReinitialize
        initialValues={{
          key: projectId ? projectData?.key : "",
          name: projectId ? projectData?.name : "",
          externalId: "",
          description: projectId ? projectData?.description : "",
          privacy: projectId ? projectData?.privacy : 1,
          taskManagement: "0",
          teamMembers: [
            {
              memberId:
                projectId && projectData?.teamMembersList
                  ? projectData.teamMembersList.map((t: any) => t?.name)
                  : [],
            },
          ],
          projectType: projectId ? projectData?.projectType : "",
          platform: 2,
        }}
        validationSchema={ProjectSchema}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          const members = values.privacy === 0 ? selectedMember : userLists;
          if (projectId) {
            const reqBody: any = {
              key: values.key,
              name: values.name,
              description: values.description,
              projectType: values.projectType,
              privacy: values.privacy,
              teamMembers: members.map((member: any) => ({
                memberId: member.identityId,
              })),
              externalId: "",
              platform: 2,
            };

            const result = await dispatch(
              updateProjectAsync({ id: projectId, reqBody })
            );
            projectList();
            setEditProject(false);
            toast.success(`Project Updated Successfully!`, TOASTIFY_CONF);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          isSubmitting,
          setFieldTouched,
          submitForm,
          isValid,
          dirty,
        }) => {
          return (
            <Form className="w-full flex flex-col h-full">
              <ModalHeader className="bg-gray-200">
                <Typography className="font-bold" variant="h3">
                  Edit Project
                </Typography>
              </ModalHeader>
              {loading ? (
                <tr>
                  <td colSpan={3}>
                    <EchopulseLoader />
                  </td>
                </tr>
              ) : (
                <>
                  <ModalBody className="px-20 py-10 pe-2">
                    <div className="grid grid-cols-2 gap-0">
                      <div>
                        <div className="w-[25.313rem]">
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Project Title{" "}
                            <span className="text-primary-100">*</span>
                          </label>
                          <Field
                            name="name"
                            render={({ field }: any) => (
                              <>
                                <TextInput
                                  {...field}
                                  onChange={(event: any) => {
                                    const inputValue = event.target.value;
                                    // setFieldValue("name", event.target.value);
                                    // debouncedValidateTitle(event.target.value);
                                    const regex = /^[a-zA-Z0-9._\s-]*$/;
                                    if (regex.test(inputValue) || inputValue === "") {
                                      setFieldValue("name", inputValue);
                                      debouncedValidateTitle(inputValue);
                                    }
                                  }}
                                  onBlur={() => setFieldTouched("name", true)}
                                  placeholder="Enter Project Title Here"
                                  value={field.value}
                                  type="text"
                                />
                                {values.name && isUniqueTitle === true && (
                                  <div className="text-primary-100 text-200 font-normal mt-1">
                                    {"Project name already exists!"}
                                  </div>
                                )}
                                {errors.name && (
                                  <div className="text-primary-100 text-200 font-normal mt-1">
                                    {errors.name}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="w-[25.313rem] mt-8">
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Project Type
                          </label>
                          <Field
                            name="projectType"
                            render={({ field }: any) => (
                              <>
                                <Dropdown
                                  {...field}
                                  onChange={(e: any) =>
                                    setFieldValue("projectType", e.value)
                                  }
                                  placeholder="Select project type"
                                  options={projectTypeOpt}
                                  type="box"
                                  value={
                                    projectTypeOpt
                                      ? projectTypeOpt.find(
                                        (option) =>
                                          option.value === values.projectType
                                      )
                                      : values.projectType
                                  }
                                  isDisabled={true}
                                />
                                {errors.projectType && touched.projectType && (
                                  <div className="text-primary-100 text-200 font-normal mt-1">
                                    {errors.projectType}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="w-[25.313rem] [&>div>textarea]:h-[5.313rem] mt-8">
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Project Description{" "}
                            <span className="text-primary-100">*</span>
                          </label>
                          <Field
                            name="description"
                            render={({ field }: any) => (
                              <>
                                <Textarea
                                  {...field}
                                  onChange={(value: string) => {
                                    setFieldValue("description", value);
                                  }}
                                  placeholder="Briefly Describe the Project Objectives, Scope, and Key Deliverables"
                                  value={values.description}
                                  maxCharacters={250}
                                  counter={true}
                                  resize
                                />

                                {errors.description && (
                                  <div className="text-primary-100 text-200 font-normal mt-1">
                                    {errors.description}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="w-[25.313rem] mt-6">
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Project Key{" "}
                            <span className="text-primary-100">*</span>
                          </label>

                          <Field
                            name="key"
                            render={({ field }: any) => (
                              <>
                                <TextInput
                                  {...field}
                                  onBlur={field.onBlur}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const inputValue = e.target.value;
                                    const sanitizedValue = inputValue
                                      .replace(/[^a-zA-Z]/g, "")
                                      .toUpperCase()
                                      .slice(0, 5);
                                    field.onChange({
                                      target: {
                                        name: field.name,
                                        value: sanitizedValue,
                                      },
                                    });
                                  }}
                                  placeholder="Enter project Key"
                                  value={field.value}
                                  type="text"
                                  disabled={projectId && true}
                                />
                                {errors.key && touched.key && (
                                  <div className="text-primary-100 text-200 font-normal mt-1">
                                    {errors.key}
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="">
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Accessible to
                          </label>
                          <div className="flex justify-start items-center gap-4">
                            <Field
                              type="radio"
                              name="privacy"
                              value={1}
                              key="privacy1"
                              render={({ field }: any) => (
                                <RadioButton
                                  label="All"
                                  {...field}
                                  selectedValue={values.privacy}
                                  onChange={() => setFieldValue("privacy", 1)}
                                />
                              )}
                            />
                            <Field
                              type="radio"
                              name="privacy"
                              value={0}
                              key="privacy0"
                              render={({ field }: any) => (
                                <RadioButton
                                  label="Selected users"
                                  {...field}
                                  selectedValue={values.privacy}
                                  onChange={() => setFieldValue("privacy", 0)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="mt-8 w-[25.313rem]">
                          {values.privacy === 0 && (
                            <div className="">
                              <label className="text-400 font-medium text-gray-300 block mb-2">
                                Members
                              </label>
                              <SearchMember
                                userList={userList}
                                setSelectedMember={setSelectedMember}
                                selectedMember={selectedMember}
                                searchString={searchString}
                                setSearchString={setSearchString}
                                initialSelectedMembers={
                                  projectData.privacy === 1
                                    ? []
                                    : projectData?.teamMembersList?.map(
                                      (t: any) => ({
                                        name: t?.name,
                                        identityId: t?.identityId,
                                      })
                                    )
                                }
                              />

                              <div className="inline-block">
                                {selectedMember.map((t: any, index: number) => (
                                  <div
                                    key={t.id}
                                    className={`inline-block ${index >= 4 && !showAll ? "hidden" : ""
                                      }`}
                                  >
                                    <div className="flex items-center px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
                                      <span className="me-1.5">
                                        {/* {userLists &&
                                          userLists.find(
                                            (user: any) =>
                                              user.identityId === t?.identityId
                                          )?.name} */}
                                          {t?.name}
                                      </span>
                                      <a
                                        onClick={() =>
                                          removeMember(t.identityId)
                                        }
                                      >
                                        <Icons
                                          name="cross"
                                          variant="stroke"
                                          size={10}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                ))}
                                {selectedMember.length > 4 && (
                                  <div
                                    className="inline-block text-center cursor-pointer px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5"
                                    onClick={toggleShowAllMembers}
                                  >
                                    {showAll
                                      ? "Collapse view"
                                      : `+${selectedMember.length - 4} others`}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div className="flex justify-between items-center w-full">
                      <div className="flex justify-start gap-5">
                        <Button
                          label="Update"
                          variant="primary"
                          size="large"
                          isDisabled={
                            isSubmitting || !isValid || isUniqueTitle === true
                          }
                        />
                        <Button
                          label="Cancel"
                          onClick={() => setEditProject(false)}
                          variant="outline"
                          size="large"
                        />
                      </div>
                    </div>
                  </ModalFooter>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditProjectModal;
