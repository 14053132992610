import { useEffect, useState } from "react";
import Icons from "../../../components/Icons/Icon";
import FilterAccordion from "../../../components/Accordion/FilterAccordion";
import Checkbox from "../../../components/CheckBox/CheckBox";
import TextInput from "../../../components/TextInput/TextInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { adminRespSortby, managerRespSortby } from "../../../utils/common";

export const EchoPulseDatePicker = styled.div`
  .react-datepicker-wrapper {
    input {
      font-size: 0.75rem;
      height: 2rem;
      line-height: 1.25rem;
      padding: 0.4rem 2rem 0.4rem 0.6rem;
      width: 100%;
      color: #232323;
      outline: none;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      &:focus {
        border: 1px solid #4a154b;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
        outline: 0;
      }
    }
  }
  .react-datepicker__navigation-icon:before {
    border-color: #232323;
    border-width: 1.5px 1.5px 0 0;
  }
  .react-datepicker__navigation {
    align-items: center;
    display: flex;
    padding: 0;
    text-align: center;
    top: 10px;
  }
  .react-datepicker__header {
    padding-top: 10px;
    border: none;
    background: #fff;
  }
  .react-datepicker__navigation-icon:before {
    top: 6px;
    height: 7px;
    width: 7px;
  }
  .react-datepicker__current-month {
    font-size: 0.875rem;
  }
  .react-datepicker__month-container {
    width: 11.75rem;
  }
  .react-datepicker__day-name {
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.3rem;
    font-size: 12px;
  }
  .react-datepicker__day {
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.3rem;
    font-size: 12px;
  }
  .react-datepicker__day--range-start {
    background: #f15845;
  }
  .react-datepicker__day--keyboard-selected {
    background: #f15845;
    color: #fff;
  }
`;
interface OrganizationTimeOffFilterProps {
  isExpanded: boolean;
  toggleFilter: () => void;
  leavebalance: any;
  selectedLeaveTypes: any;
  setSelectedLeaveTypes: React.Dispatch<React.SetStateAction<string[]>>;
  optionsTeam: any;
  setSelectedTeam: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTeam: any;
  startDaily: any;
  setStartDaily: any;
  endDaily: any;
  setEndDaily: any;
  setFilterState: any;
  filterState: any;
  selectedManagerResponse: any;
  setSelectedManagerResponse: React.Dispatch<React.SetStateAction<string[]>>;
  selectedAdminResponse: any;
  setSelectedAdminResponse: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedFinalResponse: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFinalResponse: any;
}

const OrganizationTimeOffFilter: React.FC<OrganizationTimeOffFilterProps> = ({
  isExpanded,
  toggleFilter, selectedManagerResponse, setSelectedManagerResponse, selectedAdminResponse, setSelectedAdminResponse, setSelectedFinalResponse, selectedFinalResponse,
  leavebalance, selectedLeaveTypes, setSelectedLeaveTypes, optionsTeam, setSelectedTeam, selectedTeam, startDaily, setStartDaily, endDaily, setEndDaily, setFilterState, filterState
}) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [checkboxState, setCheckboxState] = useState({
    all: false,
    active: false,
    inactive: false,
  })
  const [minDate, setMinDate] = useState<string | null>(null);
  const [minEndDate, setMinEndDate] = useState<string | null>(null);
  const [visibleLeaveCount, setVisibleLeaveCount] = useState(5);
  const [visibleTeamCount, setVisibleTeamCount] = useState(5);

  const filteredLeaveBalance = leavebalance.filter((leaveType: any) =>
    leaveType.leaves.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredTeam = optionsTeam.filter((teamName: any) =>
    teamName.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const teamsToRender = filteredTeam.filter((team: any) => team.label !== "All");
  const allChecked = searchQuery
    ? selectedLeaveTypes.length === filteredLeaveBalance.length
    : selectedLeaveTypes.length === leavebalance.length;

  const allTeam = searchQuery
    ? selectedTeam.length === filteredTeam.length
    : selectedTeam.length === optionsTeam.length;

  const handleAccordionClick = (index: any) => {
    setOpenAccordion(index === openAccordion ? null : index);
  };

  const handleCheckboxTeamChange = (label: string, isChecked: boolean) => {
    setSelectedTeam(prev => {
      if (Array.isArray(prev)) {
        if (isChecked) {
          const updated = [...prev];
          if (!updated.includes(label)) {
            updated.push(label);
          }
          return updated;
        } else {
          return prev.filter(title => title !== label);
        }
      } else {
        return [];
      }
    });

  };

  const handleCheckboxChange = (leaveTitle: string, isChecked: boolean) => {
    setSelectedLeaveTypes(prev => {
      if (isChecked) {
        const updated = [...prev];
        if (!updated.includes(leaveTitle)) {
          updated.push(leaveTitle);
        }
        return updated;
      } else {
        return prev.filter(title => title !== leaveTitle);
      }
    });

  };

  const handleSelectAll = () => {
    if (searchQuery) {
      if (selectedLeaveTypes.length === filteredLeaveBalance.length) {
        setSelectedLeaveTypes([]);
      } else {
        setSelectedLeaveTypes(filteredLeaveBalance.map((leaveType: any) => leaveType.leaves.title));
      }

    } else {
      if (selectedLeaveTypes.length === leavebalance.length) {
        setSelectedLeaveTypes([]);
      } else {
        setSelectedLeaveTypes(leavebalance.map((leaveType: any) => leaveType.leaves.title));
      }

    }
  };

  const handleSelectAllTeam = () => {
    if (searchQuery) {
      if (selectedTeam.length === filteredTeam.length) {
        setSelectedTeam([]);

      } else {
        setSelectedTeam(filteredTeam.map((team: any) => team.label));

      }
    } else {
      if (selectedTeam.length === filteredTeam.length) {
        setSelectedTeam([]);
      } else {
        setSelectedTeam(filteredTeam.map((team: any) => team.label));
      }
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleEmployeeStatusChange = (status: string, isChecked: boolean) => {
    if (status === "all") {
      setFilterState(null);
      setCheckboxState({
        all: isChecked,
        active: isChecked,
        inactive: isChecked,
      });
    } else if (status === "active") {
      if (isChecked) {
        setFilterState(true);
        setCheckboxState({
          all: false,
          active: true,
          inactive: false,
        });
      } else {
        setCheckboxState((prevState) => ({
          ...prevState,
          all: false,
          active: false,
        }));
      }
    } else if (status === "inactive") {
      if (isChecked) {
        setFilterState(false);
        setCheckboxState({
          all: false,
          active: false,
          inactive: true,
        });
      } else {
        setCheckboxState((prevState) => ({
          ...prevState,
          all: false,
          inactive: false,
        }));
      }
    }
  };

  const handleManagerResponse = (value: string, isChecked: boolean) => {
    setSelectedManagerResponse(prev => {
      if (value === "") {
        if (isChecked) {
          return managerRespSortby.map(resp => resp.value).filter(v => v !== "");
        } else {
          return [];
        }
      } else {
        if (isChecked) {
          const updated = [...prev];
          if (!updated.includes(value)) {
            updated.push(value);
          }
          return updated;
        } else {
          return prev.filter(title => title !== value);
        }
      }
    });
  };

  const handleAdminResponse = (value: string, isChecked: boolean) => {
    setSelectedAdminResponse(prev => {
      if (value === "") {
        if (isChecked) {
          return adminRespSortby.map(resp => resp.value).filter(v => v !== "");
        } else {
          return [];
        }
      } else {
        if (isChecked) {
          const updated = [...prev];
          if (!updated.includes(value)) {
            updated.push(value);
          }
          return updated;
        } else {
          return prev.filter(title => title !== value);
        }
      }
    });
  };

  const handleFinalResponse = (value: string, isChecked: boolean) => {
    setSelectedFinalResponse(prev => {
      if (value === "") {
        if (isChecked) {
          return adminRespSortby.map(resp => resp.value).filter(v => v !== "");
        } else {
          return [];
        }
      } else {
        if (isChecked) {
          const updated = [...prev];
          if (!updated.includes(value)) {
            updated.push(value);
          }
          return updated;
        } else {
          return prev.filter(title => title !== value);
        }
      }
    });
  };

  const onClearAllFilter = () => {
    setSelectedTeam([]);
    setSelectedAdminResponse([]);
    setSelectedLeaveTypes([]);
    setSelectedManagerResponse([]);
    setSelectedFinalResponse([]);
    setFilterState(null);
    setCheckboxState({
      all: false,
      active: false,
      inactive: false,
    });
    setStartDaily(null);
    setEndDaily(null);
    // if (onClearAll) {
    //   onClearAll();
    // }
  };

  useEffect(() => {
    setMinDate(startDaily);
    setMinEndDate(endDaily);

  }, [startDaily, endDaily]);

  const handleShowMoreLeaves = () => {
    setVisibleLeaveCount(prev => (prev === 5 ? filteredLeaveBalance.length : 5));
  };

  const handleShowMoreTeams = () => {
    setVisibleTeamCount(prev => (prev === 5 ? teamsToRender.length : 5));
  };
  return (
    <div
      className={`px-5 py-8 pt-0 h-full group absolute left-0 top-0 z-[20] border-r border-gray-100 bg-gray-200 rounded-s-md ease-in duration-300 overflow-y-auto overflow-x-hidden flex flex-1 
    ${isExpanded ? "w-[12.125rem]" : "w-[4rem] hover:w-[12.125rem]"}`}
    >
      <div className="w-full">
        <div className="sticky top-0 left-0 bg-gray-200 z-[99]">
          <div className="pt-8 pb-4 mb-3 flex justify-start items-center gap-2.5 relative after:bg-gray-200 after:h-[4.5rem] after:absolute after:-right-[1.3rem] after:top-0 after:w-6 after:-z[10]">
            <div className="flex gap-1.5 justify-start items-center order-2">
              <div className="text-200 font-bold text-gray-500 ms-1">
                FILTER
              </div>
              <div className="text-gray-700 text-100">|</div>
              <button className="text-200 font-normal text-primary-100 whitespace-nowrap" onClick={() => onClearAllFilter()}>
                Clear all
              </button>
            </div>
            <button
              className="relative bg-gray-100 order-1 block p-0 me-4"
              onClick={toggleFilter}
            >
              {isExpanded ? (
                <span className="absolute left-0 -top-2.5">
                  <Icons
                    name="arrow-left"
                    variant="stroke"
                    size={20}
                    className="[&>svg>path]:stroke-gray-700"
                  />
                </span>
              ) : (
                <span className="absolute left-0 -top-2.5">
                  <Icons
                    name="filterLines"
                    variant="stroke"
                    size={20}
                    className="[&>svg>path]:fill-gray-700"
                  />
                </span>
              )}
            </button>
          </div>
        </div>
        <div className="">
          <FilterAccordion
            title="Leave Type"
            iconLeft={
              <Icons
                name="joiningDate"
                variant="stroke"
                size={16}
                className="[&>svg>g>path]:stroke-gray-700"
              />
            }
            isOpen={openAccordion === 0}
            onClick={() => handleAccordionClick(0)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              <div>
                <TextInput
                  type="text"
                  placeholder="Search Leave Type"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="sm"
                  className="!text-100 !px-2.5 !py-1.5 !font-normal"
                />
              </div>
              <div className="mt-2.5">

                <Checkbox
                  onChange={handleSelectAll}
                  size="sm"
                  label="All"
                  checked={allChecked}
                />
              </div>
              {/* {filteredLeaveBalance.map((leaveType: any, index: number) => (
                <div key={index}>
                  <Checkbox
                    size="sm"
                    label={leaveType.leaves.title}
                    checked={selectedLeaveTypes.includes(leaveType.leaves.title)}
                    onChange={(e) => handleCheckboxChange(leaveType.leaves.title, e.target.checked)}
                  />
                </div>
              ))} */}
              {filteredLeaveBalance.slice(0, visibleLeaveCount).map((leaveType: any, index: number) => (
                <div key={index}>
                  <Checkbox
                    size="sm"
                    label={leaveType.leaves.title}
                    checked={selectedLeaveTypes.includes(leaveType.leaves.title)}
                    onChange={(e) =>
                      handleCheckboxChange(leaveType.leaves.title, e.target.checked)
                    }
                  />
                </div>
              ))}
              {filteredLeaveBalance.length > 5 && (
                <button
                  className="mt-2 text-gray-900 text-200"
                  onClick={handleShowMoreLeaves}
                >
                  {visibleLeaveCount === 5
                    ? `+${filteredLeaveBalance.length - 5} more`
                    : '- Less'}
                </button>
              )}
            </div>
          </FilterAccordion>
          <FilterAccordion
            title="Department"
            iconLeft={
              <Icons
                name="sequence"
                variant="stroke"
                size={16}
                className="[&>svg>g>path]:stroke-gray-700"
              />
            }
            isOpen={openAccordion === 1}
            onClick={() => handleAccordionClick(1)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              <div>
                <TextInput
                  type="text"
                  placeholder="Search Department"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="sm"
                  className="!text-100 !px-2.5 !py-1.5 !font-normal"
                />
              </div>
              <div className="mt-2.5">
                <Checkbox
                  onChange={handleSelectAllTeam}
                  size="sm"
                  label="All"
                  checked={allTeam}
                />
              </div>
              {/* {teamsToRender.map((team: any, index: number) => (
                <div className="">
                  <Checkbox
                    size="sm"
                    label={team.label}
                    checked={selectedTeam.includes(team.label)}
                    onChange={(e) => handleCheckboxTeamChange(team.label, e.target.checked)}
                  />
                </div>
              ))} */}
              {teamsToRender.slice(0, visibleTeamCount).map((team: any, index: number) => (
                <div key={index}>
                  <Checkbox
                    size="sm"
                    label={team.label}
                    checked={selectedTeam.includes(team.label)}
                    onChange={(e) => handleCheckboxTeamChange(team.label, e.target.checked)}
                  />
                </div>
              ))}
              {teamsToRender.length > 5 && (
                <button
                  className="mt-2 text-gray-900 text-200"
                  onClick={handleShowMoreTeams}
                >
                  {visibleTeamCount === 5
                    ? `+${teamsToRender.length - 5} more`
                    : '- Less'}
                </button>
              )}
            </div>
          </FilterAccordion>
          <FilterAccordion
            title="Leave Status"
            iconLeft={
              <Icons
                name="clipboard-text"
                variant="stroke"
                size={16}
                className="[&>svg>path]:stroke-gray-700"
              />
            }
            isOpen={openAccordion === 2}
            onClick={() => handleAccordionClick(2)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              {adminRespSortby.map((responseType: any, index: number) => (
                <div className="" key={index}>
                  <Checkbox
                    size="sm"
                    label={responseType?.label}
                    checked={
                      responseType.value === ""
                        ? selectedFinalResponse.length === adminRespSortby.length - 1
                        : selectedFinalResponse.includes(responseType?.value)
                    }
                    onChange={(e) => handleFinalResponse(responseType?.value, e.target.checked)}
                  />
                </div>
              ))}
            </div>
          </FilterAccordion>
          <FilterAccordion
            title="Manager Response"
            iconLeft={
              <Icons
                name="team"
                variant="stroke"
                size={16}
                className="[&>svg>path]:stroke-gray-700"
              />
            }
            isOpen={openAccordion === 3}
            onClick={() => handleAccordionClick(3)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              {managerRespSortby.map((responseType: any, index: number) => (
                <div className="" key={index}>
                  <Checkbox
                    size="sm"
                    label={responseType?.label}
                    checked={
                      responseType.value === ""
                        ? selectedManagerResponse.length === managerRespSortby.length - 1
                        : selectedManagerResponse.includes(responseType?.value)
                    }
                    onChange={(e) => handleManagerResponse(responseType?.value, e.target.checked)}
                  />
                </div>
              ))}
            </div>
          </FilterAccordion>
          <FilterAccordion
            title="Admin Response"
            iconLeft={
              <Icons
                name="team"
                variant="stroke"
                size={16}
                className="[&>svg>path]:stroke-gray-700"
              />
            }
            isOpen={openAccordion === 4}
            onClick={() => handleAccordionClick(4)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              {adminRespSortby.map((responseType: any, index: number) => (
                <div className="" key={index}>
                  <Checkbox
                    size="sm"
                    label={responseType?.label}
                    checked={
                      responseType.value === ""
                        ? selectedAdminResponse.length === adminRespSortby.length - 1
                        : selectedAdminResponse.includes(responseType?.value)
                    }
                    onChange={(e) => handleAdminResponse(responseType?.value, e.target.checked)}
                  />
                </div>
              ))}
            </div>
          </FilterAccordion>
          <FilterAccordion
            title="Employee Status"
            iconLeft={
              <Icons
                name="role"
                variant="stroke"
                size={16}
                className="[&>svg>g>path]:stroke-gray-700"
              />
            }
            isOpen={openAccordion === 5}
            onClick={() => handleAccordionClick(5)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              <div className="">
                <Checkbox
                  onChange={(e) => handleEmployeeStatusChange("all", e.target.checked)}
                  size="sm"
                  label="All"
                  checked={checkboxState.all}
                />
              </div>
              <div className="">
                <Checkbox
                  onChange={(e) => handleEmployeeStatusChange("active", e.target.checked)}
                  size="sm"
                  label="Active"
                  checked={checkboxState.active}
                />
              </div>
              <div className="">
                <Checkbox
                  onChange={(e) => handleEmployeeStatusChange("inactive", e.target.checked)}
                  size="sm"
                  label="Inactive"
                  checked={checkboxState.inactive}
                />
              </div>
            </div>
          </FilterAccordion>
          <FilterAccordion
            title="Leave Period"
            iconLeft={
              <Icons
                name="calendar"
                variant="stroke"
                size={16}
                className="[&>svg>path]:fill-gray-700"
              />
            }
            isOpen={openAccordion === 6}
            onClick={() => handleAccordionClick(6)}
            isFilterExpanded={isExpanded}
          >
            <div className="mt-3">
              <div className="">
                <label className="text-200 text-gray-300 font-medium whitespace-nowrap">
                  Start date
                </label>
                <EchoPulseDatePicker>
                  <DatePicker
                    selected={startDaily}
                    onChange={(date: Date | null, value: string | null) => {
                      setStartDaily(date);
                    }}
                    dateFormat="dd MMM yyyy"
                    showIcon
                    onFocus={(e: any) => e.target.blur()}
                    maxDate={minEndDate !== null ? minEndDate : null}
                  />
                </EchoPulseDatePicker>
              </div>
              <div className="mt-2">
                <label className="text-200 text-gray-300 font-medium whitespace-nowrap">
                  End date
                </label>
                <EchoPulseDatePicker>
                  <DatePicker
                    selected={endDaily}
                    onChange={(date: Date | null, value: string | null) => {
                      setEndDaily(date);
                    }}
                    dateFormat="dd MMM yyyy"
                    showIcon
                    onFocus={(e: any) => e.target.blur()}
                    minDate={minDate !== null ? minDate : null}
                  />
                </EchoPulseDatePicker>
              </div>
            </div>
          </FilterAccordion>
        </div>
      </div>
    </div>
  );
};

export default OrganizationTimeOffFilter;
