// import React, { ReactNode } from "react";
// interface ColorLabelProps {
//   text: string; // Input string that determines the color
//   className?:string;
//   children?: ReactNode;
// }
// const stringToHexColor = (str: string): string => {
//   let hash = 0;
//   for (let i = 0; i < str?.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash);
//   }
//   let color = "#";
//   for (let i = 0; i < 3; i++) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += ("00" + value.toString(16)).slice(-2);
//   }
//   return color;
// };
// const ColorLabel: React.FC<ColorLabelProps> = ({ text, className,children }) => {
//   const colorCode = stringToHexColor(text);
//   return (
//     <div className={`colorDots ${className}`} 
//       style={{
//         width: "4px",
//         height: "4px",
//         borderRadius: "50%",
//         backgroundColor: colorCode,
//         margin: "0 auto",
//       }}
//     >
//       {children}
//     </div>
//   );
// };
// export default ColorLabel;

import React, { ReactNode, useState, useEffect } from "react";

interface ColorLabelProps {
  text: string;
  className?: string;
  children?: ReactNode;
}

const predefinedColors = [
  "#2EB67D",
  "#FBAF97",
  "#DA3281",
  "#75C8E1",
  "#8E7AA7",
  "#00A7C9",
  "#FF3B35",
  "#B9D3C6",
  "#757784",
  "#FFBA3E",
  "#ED714A",
  "#8DB35E",
  "#C8666B",
  "#2C9A8F",
  "#5269AD",
];

const getStoredColors = () => {
  const storedColors = localStorage.getItem("assignedColors");
  return storedColors ? JSON.parse(storedColors) : {};
};

let assignedColors: { [key: string]: string } = getStoredColors();
let availableColors = [...predefinedColors].filter(color => !Object.values(assignedColors).includes(color));

const saveColorsToStorage = () => {
  localStorage.setItem("assignedColors", JSON.stringify(assignedColors));
};

const assignUniqueColor = (str: string): string => {
  if (str === "Holiday") {
    return "#2EB67D";
  }

  if (assignedColors[str]) {
    return assignedColors[str];
  }

  if (availableColors.length === 0) {
    availableColors = [...predefinedColors];
  }

  const color = availableColors.shift();
  if (color) {
    assignedColors[str] = color;
    saveColorsToStorage();
  }

  return color || "#CFC6D9";
};

const ColorLabel: React.FC<ColorLabelProps> = ({ text, className, children }) => {
  const [colorCode, setColorCode] = useState<string>("");

  useEffect(() => {
    setColorCode(assignUniqueColor(text));
  }, [text]);

  return (
    <div
      className={`colorDots ${className}`}
      style={{
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        backgroundColor: colorCode,
        margin: "0 auto",
      }}
    >
      {children}
    </div>
  );
};

export default ColorLabel;


