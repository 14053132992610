import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getListAsync } from "../../services/reducers/timeReducer";
import {
  convertMinutesToHoursAndMinutes,
  formatDate,
} from "../../utils/common";
import TimeTrackerMonthlyEmpty from "./TimeTrackerMonthlyEmpty";

type Props = {
  startOfMonth: Date;
  endOfMonth: Date;
  activeTab: string;
};
const TimeTrackerMonthly: React.FC<Props> = ({ startOfMonth, endOfMonth, activeTab }) => {
  const dispatch = useAppDispatch();
  const [list, setList] = useState<any>([]);
  const [taskList, setTaskList] = useState<any>([]);
  const { getIntegrationList } = useAppSelector((state: any) => state.app);
  const commonMonths = new Set();
  const totalHoursPerDay: { [date: string]: number } = {};
  const isFetching = useRef(false);

  useEffect(() => {
    if (activeTab === "tab3") {
      getList();
    }
  }, [startOfMonth, endOfMonth, activeTab]);

  const getList = () => {
    if (isFetching.current) return;

    isFetching.current = true;
    const request: any = {
      StartDate: formatDate(startOfMonth),
      EndDate: formatDate(endOfMonth),
      PlatformId:
        getIntegrationList?.records?.filter((item: any) => item?.platform)[0]
          ?.platform === "Jira"
          ? 0
          : 1,
      PageSize: 500,
      ViewType: 1,
      Page: 1,
    };
    dispatch(getListAsync(request)).then((res) => {
      if (res.payload) {
        setList(res.payload.item1.records);
        setTaskList(res.payload.item1.records.flatMap((t: any) => t.tasksList));
        isFetching.current = false;
      }
    });
  };

  const getMonthAbbreviation = (monthValue: any) => {
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const validMonthValue = Math.max(1, Math.min(12, monthValue));
    const monthAbbreviation = monthAbbreviations[validMonthValue - 1];
    return monthAbbreviation;
  };

  list.forEach((task: any) => {
    task.tasksList.forEach((t: any) => {
      const month = t.month;
      totalHoursPerDay[month] = (totalHoursPerDay[month] || 0) + t.timeSpent;
    });
  });

  return (
    <div className="border border-gray-100 shadow-300 bg-white rounded-md relative min-h-[8rem] pb-6 h-[calc(100vh-11.1rem)] overflow-auto">
      <div className="h-[3.75rem] bg-gray-200 rounded-tr-md sticky left-0 top-0 z-50 ps-8 pe-6">
        <div className="flex justify-start gap-0 h-full">
          <div className="flex items-center justify-start w-[calc(100%-44rem)]">
            <div className="text-500 font-bold text-gray-300">Tasks</div>
          </div>
          <div className="flex justify-start text-center gap-[0.75rem] w-[37.5rem]">
            {taskList.map((task: any) => {
              const monthAbbreviation = getMonthAbbreviation(task.month);
              if (commonMonths.has(monthAbbreviation)) {
                return null;
              }

              commonMonths.add(monthAbbreviation);

              return (
                <div
                  key={task.identifier}
                  className="flex justify-center items-center w-[5.625rem] h-full"
                >
                  <div>
                    <div className="text-500 font-bold text-gray-300">
                      {monthAbbreviation}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex justify-end text-center gap-[0.75rem]">
            <div className="flex justify-end items-center w-[5.625rem] h-full">
              <div>
                <div className="text-500 font-bold text-gray-300">Total</div>
                <div className="text-400 font-normal text-gray-300">Hours</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[3.75rem] bg-white rounded-tr-md ps-8 pe-6 sticky top-[3.75rem] left-0 z-50">
        <div className="flex justify-start gap-0 border-b border-gray-400 h-full">
          <div className="flex items-center justify-start w-[calc(100%-44rem)] h-full">
            <div className="text-400 font-normal text-gray-300">
              Total hours
            </div>
          </div>
          <div className="flex justify-start text-center gap-[0.75rem] w-[37.5rem]">
            {Object.keys(totalHoursPerDay).map((month: any) => (
              <div
                key={month}
                className="flex justify-center items-center w-[5.625rem] h-full"
              >
                <div className="text-400 font-bold text-gray-300">
                  {convertMinutesToHoursAndMinutes(totalHoursPerDay[month])}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center w-[5.625rem] h-full">
            <div className="text-400 font-bold text-primary-100">
              {convertMinutesToHoursAndMinutes(
                list.reduce((total: number, d: any) => {
                  return (
                    total +
                    d.tasksList.reduce(
                      (taskTotal: number, t: any) =>
                        taskTotal + (t.timeSpent > 0 ? t.timeSpent : 0),
                      0
                    )
                  );
                }, 0)
              )}
            </div>
          </div>
        </div>
      </div>
      {list &&
        list.map((t: any) =>
          t.identifier !== "" && list?.length > 0 ? (
            <div className="h-[4.875rem] bg-white rounded-tr-md relative ps-8 pe-6 after:absolute after:h-9 after:w-[0.1875rem] after:bg-green-100 after:top-1/2 after:-translate-y-1/2 after:left-0 after:rounded-e-md">
              <div className="flex justify-start gap-0 border-b border-gray-400 h-full">
                <div className="flex items-center justify-start w-[calc(100%-44rem)] h-full py-4">
                  <div className="w-full pe-1">
                    <div className="text-400 font-bold text-gray-300 flex items-center">
                      <span className="w-[calc(100%-28px)] line-clamp-1">
                        {t.summary}
                      </span>
                    </div>
                    <div className="text-200 font-normal text-gray-700 flex mt-1">
                      <span className="max-w-[70%] line-clamp-1">
                        {/* {t.tasksList?.project?.key} -{" "}
                        {t.tasksList?.project?.name} */}
                        {t.tasksList?.find((task: any) => task.project)?.project
                          ? `${t.tasksList.find((task: any) => task.project)?.project.key} - ${t.tasksList.find((task: any) => task.project)?.project.name}`
                          : ""}
                      </span>{" "}
                      <span className="ms-2.5 ps-2.5 border border-y-0 border-e-0 border-s-gray-700 line-clamp-1">
                        #{t.key}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex justify-start text-center gap-[0.75rem] py-4 w-[37.5rem]">
                  {t.tasksList
                    .slice()
                    .sort((a: any, b: any) => a.month - b.month)
                    .map((t1: any) => (
                      <div
                        key={`${t1.identifier}-${t1.month}`}
                        className="flex justify-center items-center w-[5.625rem] h-full rounded-md bg-gray-200"
                      >
                        <div className="text-400 font-normal text-gray-300">
                          {t1.timeSpent > 0
                            ? convertMinutesToHoursAndMinutes(t1.timeSpent)
                            : "-"}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <TimeTrackerMonthlyEmpty />
          )
        )}
    </div>
  );
};

export default TimeTrackerMonthly;
