import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Checkbox from "../../../components/CheckBox/CheckBox";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";

const SearchNewMember = styled.div`
  .searchmember-input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.1243 6.02598C17.8453 5.76906 17.3931 5.76906 17.1141 6.02598L10.0002 12.5783L2.88619 6.02598C2.60725 5.76906 2.15499 5.76906 1.87604 6.02598C1.59709 6.28291 1.59709 6.69947 1.87604 6.95639L9.49509 13.9739C9.62904 14.0973 9.81072 14.1666 10.0002 14.1666C10.1896 14.1666 10.3713 14.0973 10.5052 13.9739L18.1243 6.95639C18.4032 6.69947 18.4032 6.28291 18.1243 6.02598Z' fill='%23232323'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
    background-size: 1rem;
  }
  .active .projectName {
    background-color: #f8f8f8;
  }
`;

type Props = {
  userList?: any;
  setSelectedMember?: any;
  selectedMember?: any;
  initialSelectedMembers?: any;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  fetchUserListCustomRequest?: boolean;
  isTaskLoading?: boolean;
};

export const SearchMember: React.FC<Props> = ({
  userList,
  setSelectedMember,
  selectedMember,
  initialSelectedMembers,
  searchString,
  setSearchString,
  fetchUserListCustomRequest,
  isTaskLoading
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchInput, setSearchInput] = useState("");

  const toggleDropdown = () => {
    if (searchInput.trim() !== "") {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(!isDropdownVisible);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    setSelectedMember([]);
    // if (initialSelectedMembers) {
    //   const initialMembers = initialSelectedMembers.map((member: any) => {

    //     const matchingUser = userList?.find(
    //       (user: any) => user.name === member.name

    //     );
    //     return matchingUser || { ...member };
    //   });
    //   setSelectedMember(initialMembers);
    // }

    if (initialSelectedMembers) {
      const initialMembers = initialSelectedMembers.map((member: any) => {
        return { ...member };
      });
      setSelectedMember(initialMembers);
    } else {
      setSelectedMember([]);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (e: any) => {
    const updatedUserList = [...userList];
    const targetId = e.target.value;

    if (e.target.checked) {
      const matchingObjects = updatedUserList.filter(
        (obj) => obj.identityId === targetId
      );
      setSelectedMember((prevArray: any) => [...prevArray, ...matchingObjects]);
    } else {
      setSelectedMember((prevArray: any) =>
        prevArray.filter((obj: any) => obj.identityId !== targetId)
      );
    }
  };

  const filteredUserList = userList
    ? userList.filter((user: any) => {
      const userLowerCaseName = user.name.toLowerCase();
      const searchStringLowerCase = (searchString || "").toLowerCase();

      return userLowerCaseName.includes(searchStringLowerCase);
    })
    : [];

  return (
    <div className="w-full">
      <SearchNewMember ref={dropdownRef}>
        <div className="relative">
          <input
            type="text"
            placeholder="Search Users"
            className="outline-none w-full bg-white border border-gray-100 ps-4 pe-4 py-[0.813rem] text-400 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
          focus:border-gray-900 focus:shadow-200
          disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100 disabled:text-gray-700 searchmember-input"
            onFocus={toggleDropdown}
            onChange={(e) => setSearchString && setSearchString(e.target.value)}
          //onBlur={() => setSearchString && setSearchString("")}
          />
          {isDropdownVisible && (
            isTaskLoading ? (
              <div className="absolute left-0 top-[46px] bg-white border-gray-400 shadow-100 rounded-md z-20 w-full border flex justify-start items-center px-4 py-2.5 text-400 text-gray-300 gap-2.5 min-h-[3.5rem]">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#D9D9D9"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="#4A154B"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>{" "}
                <span className="">Loading...</span>
              </div>
            ) : (
              <ul className="bg-white border border-gray-100 rounded-md rounded-t-0 shadow-200 w-full absolute left-0 top-[3.063rem] z-50">
                <ul className="overflow-auto min-h-11 max-h-[14rem]">
                  {filteredUserList.length === 0 ? (
                    <>
                      <li className="py-3 px-5 relative font-medium w-full flex justify-center items-center">
                        No result found
                      </li>
                      {fetchUserListCustomRequest && (
                        <li className="py-1 px-5 relative font-medium w-full flex justify-center items-center">
                          Add the member in the project first.
                        </li>
                      )}
                    </>
                  ) : (
                    filteredUserList.map((t: any) => (
                      <li
                        key={t.identityId}
                        className="py-3 px-5 relative font-medium w-full flex justify-start items-center gap-5"
                      >
                        <Checkbox
                          checked={selectedMember.some(
                            (selectedUser: any) =>
                              selectedUser.identityId === t.identityId
                          )}
                          onChange={(e: any) => {
                            handleCheckboxChange(e);
                            setSearchString && setSearchString("");
                          }}
                          value={t.identityId}
                        />
                        <div className="flex justify-start items-center">
                          <div className="w-8 h-8 rounded-full overflow-hidden">
                            {t?.img ? (
                              <UserImage
                                imgKey={t?.img}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <img
                                src={getInitialsInName(t?.name ?? "N A", 24)}
                                alt={t?.name}
                                className="w-full h-full object-cover"
                              />
                            )}
                          </div>
                          <div className="ms-2.5">
                            <p className="text-400 font-medium text-gray-300">
                              {t?.name}
                            </p>
                            <p className="text-200 font-normal text-gray-300">
                              <span>{t?.title ?? t?.designation}</span> |{" "}
                              <span>{t?.team}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </ul>
            ))}
        </div>
      </SearchNewMember>
    </div>
  );
};
