import React, { useEffect, useRef, useState } from "react";
import Icons from "../../components/Icons/Icon";
import ActivityLogEmployeeModal from "./ActivityLogEmployeeModal";
import ActivityLogRmAdminModal from "./ActivityLogRmAdminModal";
import { getNotificationListAsync } from "../../services/reducers/appReducer";
import { useAppDispatch } from "../../hooks";
import { formatDate } from "../../utils/common";

type Props = {
  userProfile: any;
};

export const ActivityLog: React.FC<Props> = ({ userProfile }) => {
  const [activityEmployee, setActivityEmployee] = useState<boolean>(false);
  const isFetching = useRef(false);
  const dispatch = useAppDispatch();
  const [readNotification, setReadNotification] = useState(false);
  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - ((dayOfWeek + 6) % 7));
  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(() => {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    return endOfWeek;
  });

  useEffect(() => {
    getNotification();
  }, [userProfile]);

  const getNotification = async () => {
    // if (isFetching.current) return;
    // isFetching.current = true;
    let IsRM = null;

    if (userProfile?.roleName === "Employee") {
      IsRM = userProfile?.isReportingManager === true ? true : null;
    } else if (userProfile?.roleName === "Admin") {
      IsRM = userProfile?.isReportingManager === false ? false : null; 
    }

    const request = {
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
      IsRM: IsRM,
      Page: 1,
      PageSize: 10,
      Team: "",
      Name: "",
    };
    const response = await dispatch(getNotificationListAsync(request));
    setReadNotification(response?.payload?.item1 > 0 ? true : false);
    // isFetching.current = false;
  };

  return (
    <>
      <button
        className="absolute right-2 top-2/4 -translate-y-1/2"
        onClick={() => setActivityEmployee(true)}
      >
        <Icons name="activity-2" variant="stroke" size={25} className="mt-1" />
        {readNotification === true && (
          <span className="h-1.5 w-1.5 bg-error absolute right-px top-1 rounded-full"></span>
        )}
      </button>
      {activityEmployee && (
        <ActivityLogRmAdminModal
          onClose={() => setActivityEmployee(false)}
          userProfile={userProfile}
          handleModal={setActivityEmployee}
        />
      )}
    </>
  );
};
