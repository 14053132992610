import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { formatDateMonthYear } from "../../utils/common";
import { addMonths, isSameDay, parseISO, addYears, getYear } from "date-fns";
import ColorLabel from "./ColorLable";

export const EchoPulseTimeOff = styled.div`
  .react-datepicker {
    border: none;
    border-radius: 0 0 0 0;
    // padding: 0 1.25rem;
    .react-datepicker__header {
      border: none;
      background-color: transparent;
      padding: 0 0 0.5rem 0;
      font-size: 0.75rem;
      font-weight: 700;
    }
    .react-datepicker__navigation {
      display: none !important;
    }
    .react-datepicker__month {
      margin: 0.5rem 0 0 0;
    }
    .react-datepicker__current-month {
      margin-bottom: 0.5rem;
      font-size: 0.813rem;
      font-wieght: 700;
    }
    .react-datepicker__day {
      padding: 0.5rem 0.375rem;
      width: 1.625rem;
      height: 1.625rem;
      line-height: 100%;
      //   margin: 0;
      font-size: 0.688rem;
      font-weight: 400;
      color: #0a0a0a;
      border-radius: 50%;
    }
    .react-datepicker__day--selected {
      background: #fff;
    }
    .react-datepicker__day--today {
      background: #ededed;
    }
    // .react-datepicker__day--keyboard-selected:hover {
    //   background-color: #bad9f1;
    //   cursor:auto;
    // }
    .react-datepicker__day--001.highlight-day[tabindex="0"] {
      background-color: #bad9f1;
    }
    .react-datepicker__day--selected.react-datepicker__day--001:hover {
      background-color: #f0f0f0;
      // cursor:auto;
    }
    .react-datepicker__day--001.highlight-day[tabindex="0"]:hover {
      background-color: #bad9f1;
      cursor: auto;
    }
    .react-datepicker__day--001.react-datepicker__day--disabled:hover {
      background-color: #FFF;
      cursor: auto;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: #ffffff;
    }
    .highlight-day.react-datepicker__day--keyboard-selected {
      background-color: #bad9f1;
      cursor:auto;
    }
    .highlight-day.react-datepicker__day--keyboard-selected:hover {
      background-color: #bad9f1;
      cursor:auto;
    }
  }
  .slick-slide {
    transition: all 0.3s;
    position: unset;
    width: 14.375rem !important;
    margin: 0 1.25rem;
  }
  .slick-slider {
    position: unset;
  }
  .slick-arrow {
    background-color: transparent;
    border-radius: 50%;
    opacity: 1;
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-position: center;
    overflow: hidden;
    color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    &.slick-prev {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='7' height='11' viewBox='0 0 7 11' fill='none'%3e%3cpath d='M5.94085 0.27005C6.0885 0.27005 6.24688 0.322844 6.3632 0.439158C6.5954 0.671365 6.5954 1.04092 6.3632 1.27312L2.36167 5.28543L6.3632 9.28696C6.59541 9.51916 6.59541 9.88872 6.3632 10.1209C6.13099 10.3535 5.76144 10.3535 5.52923 10.1316L1.10535 5.70777C0.989038 5.59146 0.936244 5.4438 0.936244 5.28542C0.936244 5.12704 0.999761 4.97939 1.10535 4.86308L5.52923 0.439196C5.64554 0.322881 5.7932 0.27005 5.94085 0.27005Z' fill='black'/%3e%3c/svg%3e");
      right: auto;
      left: 5%;
      background-repeat: no-repeat;
      &:before {
        display: none;
      }
    }
    &.slick-next {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='7' height='11' viewBox='0 0 7 11' fill='none'%3e%3cpath d='M1.25202 10.2967C1.10437 10.2967 0.945987 10.2439 0.829673 10.1276C0.597466 9.89541 0.597466 9.52585 0.829673 9.29365L4.8312 5.28135L0.829673 1.27981C0.597466 1.04761 0.597466 0.678054 0.829673 0.445848C1.06188 0.213229 1.43143 0.213229 1.66364 0.435124L6.08752 4.859C6.20383 4.97532 6.25663 5.12297 6.25663 5.28135C6.25663 5.43973 6.19311 5.58738 6.08752 5.7037L1.66364 10.1276C1.54733 10.2439 1.39967 10.2967 1.25202 10.2967Z' fill='black'/%3e%3c/svg%3e ");
      left: auto;
      background-repeat: no-repeat;
      right: 5%;
      &:before {
        display: none;
      }
    }
    &.slick-disabled {
      opacity: 0.4;
      display: none !important;
    }
  }
  .slick-list {
    padding: 0 !important;
    position: unset;
  }
  .react-datepicker__day--disabled {
    color: #9e9e9e !important;
  }
  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
    .colorDots {
      display: none !important;
    }
  }
`;

type Props = {
  setStartMonthTeam?: React.Dispatch<React.SetStateAction<string>>;
  setEndMonthTeam?: React.Dispatch<React.SetStateAction<string>>;
  setStartMonth?: React.Dispatch<React.SetStateAction<string>>;
  setEndMonth?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
  calenderDetail: any;
  fyEndDate: any;
  fyStartDate: any;
  setCurrentSlide?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentSlideTeam?: React.Dispatch<React.SetStateAction<number>>;
  currentSlide?: number;
  currentSlideTeam?: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  selectedDate: any;
};

const CalenderSlider: React.FC<Props> = ({
  setStartMonthTeam,
  setEndMonthTeam,
  setStartMonth,
  setEndMonth,
  calenderDetail,
  setSelectedDate,
  fyEndDate,
  fyStartDate,
  setCurrentSlide,
  setCurrentSlideTeam,
  currentSlide,
  currentSlideTeam,
  setCurrent,
  current,
  selectedDate,
}) => {
  const currentDate = new Date();
  const startDate = new Date(fyStartDate);
  const [initialMonthIndex, setInitialMonthIndex] = useState(
    startDate.getMonth()
  );
  const endMonthIndex = initialMonthIndex === 11 ? 0 : initialMonthIndex + 1;
  const fallbackSlide =
    currentSlide !== undefined ? currentSlide : currentSlideTeam;
  const effectiveSlide = fallbackSlide ?? 0;

  const startMonthYear =
    currentDate.getFullYear() + Math.floor(effectiveSlide / 12);
  // setStartMonth(
  //   formatDateMonthYear(initialMonthIndex + currentSlide - 1, startMonthYear)
  // );
  const endMonthYear =
    currentDate.getFullYear() + Math.floor(effectiveSlide / 12);
  // setEndMonth(
  //   formatDateMonthYear(endMonthIndex + currentSlide - 1, endMonthYear, true)
  // );

  useEffect(() => {
    // if (currentSlide >= 0) {
    if (setStartMonth && currentSlide) {
      setStartMonth(
        formatDateMonthYear(
          initialMonthIndex + currentSlide - current,
          startMonthYear
        )
      );
    }
    if (setEndMonth && currentSlide) {
      setEndMonth(
        formatDateMonthYear(
          endMonthIndex + currentSlide - current,
          endMonthYear,
          true
        )
      );
    }
    if (setStartMonthTeam && currentSlideTeam) {
      setStartMonthTeam(
        formatDateMonthYear(
          initialMonthIndex + currentSlideTeam - current,
          startMonthYear
        )
      );
    }
    if (setEndMonthTeam && currentSlideTeam) {
      setEndMonthTeam(
        formatDateMonthYear(
          endMonthIndex + currentSlideTeam - current,
          endMonthYear,
          true
        )
      );
    }
    // }
  }, [currentSlide, current, initialMonthIndex, currentSlideTeam]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: currentDate.getMonth(),
    // centerMode: true,
    afterChange: (current: number) => {
      if (setCurrentSlide) {
        setCurrentSlide(current);
        // setCurrent(current);
      }
      if (setCurrentSlideTeam) {
        setCurrentSlideTeam(current);
      }
      setInitialMonthIndex(currentDate.getMonth()); // Update for next icon click
    },
    beforeChange: (current: number, next: number) => {
      if (next < current) {
        setInitialMonthIndex(currentDate.getMonth() - 2); // Update for prev icon click
      }
    },
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setInitialMonthIndex(currentDate.getMonth());
  }, []);

  const generateMonths = () => {
    const months = [];
    const startDate = new Date(fyStartDate);
    const endDate = new Date(fyEndDate);

    let currentMonth = startDate.getMonth();
    let currentYear = startDate.getFullYear();

    while (
      currentYear < endDate.getFullYear() ||
      (currentYear === endDate.getFullYear() &&
        currentMonth <= endDate.getMonth())
    ) {
      const monthDate = new Date(currentYear, currentMonth, 1);
      months.push({ date: monthDate, name: monthDate });

      if (currentMonth === 11) {
        currentMonth = 0;
        currentYear++;
      } else {
        currentMonth++;
      }
    }

    return months;
  };

  const months = generateMonths();
  const enabledDates = calenderDetail.map((item: any) =>
    parseISO(item.timeOffDate)
  );
  const isDateEnabled = (date: Date, monthIndex: number) => {
    const startDateOfMonth = new Date(months[monthIndex].date);
    const endDateOfMonth = new Date(
      startDateOfMonth.getFullYear(),
      startDateOfMonth.getMonth() + 1,
      0
    );

    if (date < startDateOfMonth || date > endDateOfMonth) {
      return false;
    }

    return enabledDates.some((enabledDate: Date) =>
      isSameDay(date, enabledDate)
    );
  };

  const getColorLabel = (date: Date) => {
    const leaveDetail = calenderDetail.find((item: any) =>
      isSameDay(date, parseISO(item.timeOffDate))
    );

    if (leaveDetail) {
      const leaveType = leaveDetail?.timeOffType;
      console.log(leaveType, "leaveType");
      const title = leaveDetail?.title || "No Title";
      const label = leaveType === 0 ? "Holiday" : leaveType === 1 ? title : "";
      //const label = title;
      console.log(label, "label");
      return <ColorLabel text={label} />;
    }
    return null;
  };

  return (
    <EchoPulseTimeOff>
      <div className="pt-7 pb-4 flex justify-center items-center relative">
        <div className="w-[33.75rem]">
          <Slider {...settings}>
            {months.map((month, index) => (
              <div key={index}>
                <div className="w-full">
                  <DatePicker
                    selected={month.date}
                    inline
                    onChange={(date: Date) => setSelectedDate(date)}
                    filterDate={(date: Date) => isDateEnabled(date, index)}
                    onSelect={(date: Date) => setSelectedDate(date)}
                    // dayClassName={(date: Date) =>
                    //   (isDateEnabled(date, index) && selectedDate) ? "highlight-day" : ""
                    // }
                    dayClassName={(date: Date) => {
                      const ariaLabel = date.toDateString(); // Example condition
                      return isDateEnabled(date, index) &&
                        selectedDate &&
                        ariaLabel === selectedDate.toDateString()
                        ? "highlight-day"
                        : "";
                    }}
                    renderDayContents={(day: number, date: Date) => {
                      const labelComponent = getColorLabel(date);
                      return (
                        <div>
                          {day}
                          {labelComponent}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </EchoPulseTimeOff>
  );
};

export default CalenderSlider;
