import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button/Button";
import jiraImage from "../../../images/jira.png";
import circleImage from "../../../images/clickup.png";
import echoImage from "../../../images/favicon.svg";
import Typography from "../../../components/Typography/Typography";
import AddProjectModal from "./AddProjectModal";
import InvitedMemberModal from "./InvitedMemberModal";
import ProjectEmpty from "./ProjectEmpty";
import { ProjectDropdown } from "../../../components/ProjectDropdown/ProjectDropdown";
import EditProjectModal from "./EditProjectModal";
import { useAppDispatch } from "../../../hooks";
import {
  deleteProjectAsync,
  getProjectAsync,
  getProjectTeamMemberListAsync,
  lockProjectAsync,
  unLockProjectAsync,
} from "../../../services/reducers/adminReducer";
import getInitialsInName from "../../../helper/GetNameInitials";
import EchopulseLoader from "../../loader/EchopulseLoader";
import {
  getUserProfileAsync,
  getUsersAsync,
} from "../../../services/reducers/appReducer";
import AddProjectTaskModal from "./AddProjectTaskModal";
import { useNavigate } from "react-router-dom";
import ModalDelete from "../../TimeTracker/ModalDeletePopup";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import SearchProjects from "./SearchProjects";
import CustomProjectEmpty from "./CustomProjectEmpty";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import LockedProjectEmpty from "./LockedProjectEmpty";
import Icons from "../../../components/Icons/Icon";

const Projects = () => {
  const [addProject, setAddProject] = useState<boolean>(false);
  const [editProject, setEditProject] = useState<boolean>(false);
  const [inviteMember, setInviteMember] = useState<boolean>(false);
  const [addTask, setAddTask] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [projectList, setProjectList] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [userSearchString, setUserSearchString] = useState("");
  const [userTaskSearchString, setUserTaskSearchString] = useState("");
  const [userList, setUserList] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [projectId, setProjectId] = useState("");
  const navigate = useNavigate();
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isConfirmationVisible1, setConfirmationVisible1] = useState(false);
  const [isConfirmationUnLockVisible, setConfirmationUnLockVisible] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("1");
  const [selectedMember, setSelectedMember] = useState<any>([]);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [fetchUserListCustomRequest, setFetchUserListCustomRequest] =
    useState(false);
  const isFetching = useRef(false);

  const userProject = () => {
    if (fetchUserListCustomRequest) {
      const request = {
        ProjectId: projectId,
        Page: currentPage,
        PageSize: pageSize,
        Team: "",
        IsOrderByDesc: false,
        SearchString: userTaskSearchString,
      };
      dispatch(getProjectTeamMemberListAsync(request)).then((res) => {
        setUserList(res?.payload?.item2);
      });
    } else {
      const request = {
        page: currentPage,
        pageSize: pageSize,
        searchString: userSearchString,
      };
      dispatch(getUsersAsync(request)).then((res) => {
        // setUserList(res?.payload?.users);
        const activeUsers = res?.payload?.users?.filter((user:any) => user.status === "Active");
        setUserList(activeUsers);
      });
    }
  };
  const debouncedUserSearch = useDebounce(userProject, 1000);

  useEffect(() => {
    debouncedUserSearch();
  }, [
    userSearchString,
    fetchUserListCustomRequest,
    projectId,
    userTaskSearchString,
  ]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    if (isFetching.current) return; 

    isFetching.current = true;
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
        isFetching.current = false;
      }
    });
  };
  const getProject = () => {
    setLoading(true);
    const request = {
      project: searchString,
      Page: currentPage,
      PageSize: pageSize,
    };
    dispatch(getProjectAsync(request)).then((res) => {
      //setProjectList(res?.payload);
      setLoading(false);

      if (selectedOption === "2") {
        setProjectList(
          res?.payload?.filter((item: any) => item.project.projectStatus === 0)
        );
      } else if (selectedOption === "1") {
        setProjectList(
          res?.payload?.filter(
            (item: any) =>
              item.platform === 2 && item.project.projectStatus === 0
          )
        );
      } else if (selectedOption === "3") {
        setProjectList(
          res?.payload?.filter((item: any) => item.project.projectStatus === 4)
        );
      }
    });
  };
  const debouncedSearch = useDebounce(getProject, 1000);

  useEffect(() => {
    debouncedSearch();
  }, [searchString, selectedOption]);

  const handleEdit = (id: string) => {
    setProjectId(id);
    setEditProject(true);
    setFetchUserListCustomRequest(false);
  };

  const handleAdd = (id: string) => {
    setProjectId(id);
    setAddTask(true);
    setFetchUserListCustomRequest(true);
  };
  
  const handleView = (id: string) => {
    navigate(`/projects/${id}`);
  };

  const handleInvite = (id: string) => {
    setProjectId(id);
    setInviteMember(true);
  };

  const handleLock = (id: string) => {
    setConfirmationVisible1(true);
    setProjectId(id);
  };

  const handleUnLock = (id: string) => {
    setConfirmationUnLockVisible(true);
    setProjectId(id);
  };

  return (
    <div>
      <div className="flex justify-between items-center ms-1 relative">
        <div className="w-auto">
          <ProjectDropdown
            value={{
              label:
                selectedOption === "1"
                  ? "Custom Projects"
                  : selectedOption === "2"
                  ? "All Projects"
                  : selectedOption === "3"
                  ? "Locked Projects"
                  : "",
              value: selectedOption,
            }}
            onChange={(selectedValue, actionMeta) => {
              if (selectedValue && !Array.isArray(selectedValue)) {
                const newValue =
                  selectedValue.value !== undefined
                    ? String(selectedValue.value)
                    : "";

                setSelectedOption(newValue);
                setSearchString("");
              } else {
                setSelectedOption("");
              }
            }}
            placeholder="Select"
            type="smbox"
            options={[
              {
                label: "Custom Projects",
                value: "1",
              },
              {
                label: "All Projects",
                value: "2",
              },
              {
                label: "Locked Projects",
                value: "3",
              },
            ]}
          />
        </div>
        <div className="flex justify-end items-center gap-2.5">
          <div>
            <SearchProjects
              title="Projects"
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </div>
          {(userProfile?.roleName === "Super Admin" ||
            userProfile?.roleName === "Admin") && (
            <Button
              label="Create Project"
              onClick={() => {
                setFetchUserListCustomRequest(false);
                setAddProject(true);
              }}
              variant="primary"
              size="extramedium"
            />
          )}
        </div>
        <div className="absolute left-0.5 -bottom-2.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="136"
            height="2"
            viewBox="0 0 136 2"
            fill="none"
          >
            <path
              d="M1 1H135"
              stroke="#F15845"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>

      <div className="border border-gray-100 shadow-300 bg-white rounded-md relative min-h-[10rem] h-[calc(100vh-11.1rem)] mt-2.5 overflow-auto">
        <div className="">
          <table className="w-full">
            <thead className="sticky left-0 top-0 bg-gray-200 z-20">
              <tr>
                <th className="bg-gray-200 px-[1.875rem] py-4 text-left text-gray-700 text-200 font-bold rounded-tl-md w-[60%]">
                  Projects
                </th>
                <th className="bg-gray-200 px-[1.875rem] py-4 text-left text-gray-700 text-200 font-bold">
                  Members
                </th>
                {/* <th className="bg-gray-200 px-[1.875rem] py-4 text-left text-gray-700 text-200 font-bold">
                      Status
                    </th> */}
                <th className="bg-gray-200 px-[1.875rem] py-4 text-end text-gray-700 text-200 font-bold pe-10">
                  {/* Actions */}
                </th>
              </tr>
            </thead>
            {loading ? (
              <EchopulseLoader />
            ) : projectList?.length > 0 ? (
              <tbody>
                {projectList &&
                  projectList
                    .filter((project: any) =>
                      searchString
                        ? project?.project?.name
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                        : true
                    )
                    .map((t: any) => (
                      <tr>
                        <td className="px-[1.875rem] py-5 border-b border-gray-400">
                          <div className="flex justify-start items-center">
                            <div className="w-8 h-8 overflow-hidden">
                              <img
                                src={
                                  t?.project?.platform === 0
                                    ? jiraImage
                                    : t?.project?.platform === 1
                                    ? circleImage
                                    : echoImage
                                }
                                alt=""
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <div className={`ms-4 w-[calc(100%-48px)] ${
                                  t?.project?.platform === 2 &&
                                  t?.project?.projectStatus === 0
                                    ? "cursor-pointer"
                                    : ""
                                }`} onClick={() =>
                                  t?.project?.platform === 2 &&
                                  t?.project?.projectStatus === 0
                                    ? handleView(t?.project?.id)
                                    : {}
                                }>
                              <div
                                className="text-gray-300 font-bold text-600 line-clamp-1 m-0"
                              >
                                {t?.project?.name}
                              </div>

                              {/* <Typography
                                className="text-gray-300 font-normal mt-1 line-clamp-1"
                                size="lg"
                                variant="p"
                              >
                                {t?.company}
                              </Typography> */}
                            </div>
                          </div>
                        </td>
                        <td className="px-[1.875rem] py-4 border-b border-gray-400">
                          <div
                            className={`flex justify-start items-center group relative max-w-max ${
                              t?.project?.platform === 2 ? "cursor-pointer" : ""
                            } text-600`}
                            onClick={() =>
                              t?.project?.platform === 2
                                ? handleInvite(t?.project?.id)
                                : {}
                            }
                          >
                            {t?.teamMemberList?.length > 0 &&
                            t?.teamMembersCount === 1 ? (
                              <div className="w-8 h-8 rounded-full overflow-hidden">
                                {t.teamMemberList[0]?.profilePic ? (
                                  <img
                                    src={t.teamMemberList[0]?.profilePic}
                                    alt="defaultImage"
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      t.teamMemberList[0]?.name ?? "N A",
                                      24
                                    )}
                                    alt={t.teamMemberList[0]?.name}
                                    className="w-full h-full object-cover"
                                  />
                                )}
                              </div>
                            ) : t?.teamMemberList?.length > 0 &&
                              t?.teamMembersCount === 2 ? (
                              <>
                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                  {t.teamMemberList[0]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[0]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[0]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[0]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                                <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                  {t.teamMemberList[1]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[1]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[1]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[1]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                              </>
                            ) : t?.teamMemberList?.length > 0 &&
                              t?.teamMembersCount === 3 ? (
                              <>
                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                  {t.teamMemberList[0]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[0]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[0]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[0]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                                <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                  {t.teamMemberList[1]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[1]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[1]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[1]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                                <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                  {t.teamMemberList[2]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[2]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[2]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[2]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                              </>
                            ) : t?.teamMemberList?.length > 0 &&
                              t?.teamMembersCount > 3 ? (
                              <>
                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                  {t.teamMemberList[0]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[0]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[0]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[0]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                                <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                  {t.teamMemberList[1]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[1]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[1]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[1]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                                <div className="w-8 h-8 rounded-full overflow-hidden -ms-3">
                                  {t.teamMemberList[2]?.profilePic ? (
                                    <img
                                      src={t.teamMemberList[2]?.profilePic}
                                      alt="defaultImage"
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t.teamMemberList[2]?.name ?? "N A",
                                        24
                                      )}
                                      alt={t.teamMemberList[2]?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                              </>
                            ) : null}

                            {t?.teamMembersCount > 3 ? (
                              <div className="rounded-full overflow-hidden ms-1 first:ms-0 flex justify-center items-center text-primary-100 text-200 font-bold">
                                <span>{`+ ${t?.teamMembersCount - 3}`}</span>
                              </div>
                            ) : t?.teamMembersCount === 0 ? (
                              <div className="w-8 h-8 rounded-full overflow-hidden -ms-3 first:ms-0 bg-gray-600 flex justify-center items-center text-primary-100 text-200 font-bold">
                                <span>0</span>
                              </div>
                            ) : null}
                          </div>
                        </td>

                        <td className="px-[1.875rem] py-5 border-b border-gray-400 pe-10">
                          <div className="flex justify-end items-center gap-5">
                            {t?.platform === 2 &&
                              t?.project?.projectStatus === 0 && (
                                <Button
                                  label="Add Task"
                                  onClick={() => handleAdd(t?.project?.id)}
                                  variant="outline"
                                  size="small"
                                />
                              )}
                            {(userProfile?.roleName === "Super Admin" ||
                              userProfile?.roleName === "Admin") &&
                              t?.platform === 2 &&
                              t?.project?.projectStatus === 0 && (
                                <>
                                  <button
                                    onClick={() => handleEdit(t?.project?.id)}
                                    className="ms-3 text-gray-300"
                                  >
                                    <Icons
                                      name="edit"
                                      variant="stroke"
                                      size={20}
                                      className="mt-1.5"
                                    />
                                  </button>
                                </>
                              )}
                            {(userProfile?.roleName === "Super Admin" ||
                              userProfile?.roleName === "Admin") &&
                              t?.project?.projectStatus === 0 && (
                                <>
                                  <button
                                    className="-mb-1"
                                    onClick={() => handleLock(t?.project?.id)}
                                  >
                                    <Icons
                                      name="lock-2"
                                      variant="stroke"
                                      size={20}
                                    />
                                  </button>
                                </>
                              )}

                            {(userProfile?.roleName === "Super Admin" ||
                              userProfile?.roleName === "Admin") &&
                              t?.project?.projectStatus === 4 && (
                                <button
                                  className="text-secondary-100 text-400 font-bold text-right"
                                  onClick={() => handleUnLock(t?.project?.id)}
                                >
                                  <Icons
                                    name="unlock"
                                    variant="stroke"
                                    size={18}
                                  />
                                </button>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            ) : (
              <tr>
                <td colSpan={3}>
                  {selectedOption === "2" ? (
                    <ProjectEmpty />
                  ) : selectedOption === "3" ? (
                    <LockedProjectEmpty />
                  ) : (
                    <CustomProjectEmpty />
                  )}
                </td>
              </tr>
            )}
          </table>
        </div>
        {inviteMember && (
          <InvitedMemberModal
            onClose={() => setInviteMember(false)}
            projectId={projectId}
          />
        )}
        {addTask && (
          <AddProjectTaskModal
            onClose={() => setAddTask(false)}
            userList={userList}
            setAddTask={setAddTask}
            projectId={projectId}
            searchString={userTaskSearchString}
            setSearchString={setUserTaskSearchString}
            fetchUserListCustomRequest={fetchUserListCustomRequest}
            userProfile={userProfile}
            //projectData={projectData}
          />
        )}
        {editProject && (
          <EditProjectModal
            onClose={() => setEditProject(false)}
            projectId={projectId}
            setEditProject={setEditProject}
            projectList={getProject}
            userList={userList}
            searchString={userSearchString}
            setSearchString={setUserSearchString}
            selectedMember={selectedMember}
            setSelectedMember={setSelectedMember}
            // projectData={projectData}
          />
        )}
        {addProject && (
          <AddProjectModal
            onClose={() => setAddProject(false)}
            setAddProject={setAddProject}
            userList={userList}
            projectList={getProject}
            searchString={userSearchString}
            setSearchString={setUserSearchString}
          />
        )}
        {isConfirmationVisible && (
          <ModalDelete
            onCancel={() => {
              setConfirmationVisible(false);
              setProjectId("");
            }}
            onConfirm={(requestType) => {
              if (requestType === "Yes") {
                dispatch(deleteProjectAsync(projectId)).then((res) => {
                  getProject();
                });
              }
              setConfirmationVisible(false);
              setProjectId("");
            }}
            chldLable="Are you sure you want to delete this Project? "
          />
        )}
        {isConfirmationVisible1 && (
          <ModalDelete
            onCancel={() => {
              setConfirmationVisible1(false);
              setProjectId("");
            }}
            onConfirm={(requestType) => {
              if (requestType === "Yes") {
                dispatch(lockProjectAsync(projectId)).then((res) => {
                  toast.success(`Project Locked Successfully!`, TOASTIFY_CONF);
                  getProject();
                });
              }
              setConfirmationVisible1(false);
              setProjectId("");
            }}
            chldLable="Confirm Project Lock"
            headerLable="Are you sure you want to lock this project? Locking will restrict all team members from adding tasks or logging time for this project."
          />
        )}
        {isConfirmationUnLockVisible && (
          <ModalDelete
            onCancel={() => {
              setConfirmationUnLockVisible(false);
              setProjectId("");
            }}
            onConfirm={(requestType) => {
              if (requestType === "Yes") {
                dispatch(unLockProjectAsync(projectId)).then((res) => {
                  toast.success(
                    `Project Unlocked Successfully!`,
                    TOASTIFY_CONF
                  );
                  getProject();
                });
              }
              setConfirmationUnLockVisible(false);
              setProjectId("");
            }}
            chldLable=" Confirm Project Unlock "
            headerLable="Are you sure you want to unlock this project? Once unlocked, all team members will regain the ability to add tasks and log hours for this project."
          />
        )}
      </div>
    </div>
  );
};

export default Projects;
