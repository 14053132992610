import { API_URL } from "../sharedService/constants";
import { http, httpAuth, httpEmployee } from "../sharedService/httpService";

export function createIntegration(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/AppIntegration/Create`,
    reqBody
  );
}
export function getIntegrationList() {
  return http.get(`${API_URL.INTEGRATION_API_URL}api/AppIntegration/List`);
}
export function getUserProfile() {
  return httpEmployee.get(`${API_URL.EMPLOYEE_API_URL}User/getUserProfile`);
}
export function getUserDetails(id: string) {
  return httpAuth.post(`${API_URL.AUTH_API_URL}${id}/GetUser`);
}
export function getUserIdentity(id: string) {
  return httpAuth.post(`${API_URL.AUTH_API_URL}${id}/GetUserByIdentityId`);
}
export function deleteIntegration(id: string) {
  return http.delete(`${API_URL.INTEGRATION_API_URL}api/AppIntegration/${id}`);
}
export function getAllUsers(reqBody: any) {
  return httpAuth.post(`getUserList`, reqBody);
}
export function getTeams(reqBody: any) {
  return httpAuth.post(`getTeams`, reqBody);
}
export function getIntegrationKeyList(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/AppIntegration/KeyStrength/${reqBody.BaseUrl}/${reqBody.ApiValue}/${reqBody.PlatformId}`
  );
}
export function getReportees(reqBody: any) {
  return httpAuth.post(`GetReporteesByIdentityId`, reqBody);
}

export function getNotificationList(reqBody: any) {
  let baseUrl = `api/Notification/List?StartDate=${encodeURIComponent(reqBody.StartDate)}&EndDate=${encodeURIComponent(reqBody.EndDate)}&Page=${reqBody.Page}&PageSize=${reqBody.PageSize}&Team=${encodeURIComponent(reqBody.Team)}`;
  if (reqBody.Name !== "") {
    baseUrl += `&Name=${encodeURIComponent(reqBody.Name)}`;
  } 
  if (reqBody.IsRM !== null) {
    baseUrl += `&IsRM=${reqBody.IsRM}`;
  }

  return http.get(`${API_URL.INTEGRATION_API_URL}${baseUrl}`);
}

export function updateNotification(id: string, reqBody: any) {
  return http.patch(
    `${API_URL.INTEGRATION_API_URL}api/Notification/${id}`,
    reqBody
  );
}