import React from "react";
import Typography from "../../../components/Typography/Typography";

const OrganizationTimeOffEmpty = () => {
  return (
    <div className="flex justify-center items-center h-[calc(100vh-21.25rem)] w-full text-center">
      <div>
        <div className="w-[12.875rem] flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="101"
            viewBox="0 0 101 101"
            fill="none"
          >
            <circle cx="50.5" cy="50.5" r="50.5" fill="#F4F4F4" />
            <mask
              id="mask0_8704_9596"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="101"
              height="101"
            >
              <circle cx="50.5" cy="50.5" r="50.5" fill="#F4F4F4" />
            </mask>
            <g mask="url(#mask0_8704_9596)">
              <path
                d="M39.5752 33.5221C39.5752 37.8283 42.1713 41.7127 46.1498 43.3614C50.1282 45.0101 54.7105 44.098 57.7544 41.0542C60.8015 38.007 61.7133 33.4282 60.0648 29.4496C58.4163 25.4678 54.5319 22.875 50.2255 22.875C44.3487 22.8815 39.5818 27.6456 39.5752 33.5221Z"
                fill="white"
              />
              <path
                d="M62.4922 33.5226C62.4954 36.2841 61.5576 38.9678 59.8345 41.1257C63.0601 41.7293 66.383 40.7947 68.8264 38.6043L68.8361 38.5978H68.8329C72.1332 35.6577 73.2722 30.9848 71.6983 26.8536C70.1212 22.7226 66.1589 19.9968 61.7394 20C59.3477 19.9968 57.0275 20.8048 55.1583 22.2975C57.339 23.2548 59.192 24.8287 60.4933 26.8211C61.7945 28.8136 62.4889 31.1407 62.4922 33.5226Z"
                fill="white"
              />
              <path
                d="M28.0546 30.6504C28.0448 33.8144 29.4435 36.8161 31.8741 38.841C34.3014 40.8659 37.5075 41.7032 40.6159 41.1256C38.2502 38.1531 37.4001 34.2493 38.3184 30.5627C39.2336 26.8731 41.8101 23.8227 45.2888 22.2975C43.4229 20.808 41.1027 19.9968 38.7111 20C32.831 20.0065 28.0645 24.7706 28.0546 30.6504Z"
                fill="white"
              />
              <path
                d="M71.9251 62.4949C70.9093 53.6458 66.3273 47.2791 57.952 43.0439C55.7681 44.8222 53.0422 45.7925 50.2255 45.7925C47.4088 45.7925 44.6829 44.8222 42.499 43.0439C34.1234 47.2788 29.5411 53.6458 28.5259 62.4949C28.4253 63.3873 28.9932 64.2212 29.8629 64.4517C35.6391 65.9574 42.7909 66.3695 50.226 66.6908C57.6605 66.3728 64.813 65.9606 70.5891 64.4582V64.4549C71.4588 64.2213 72.0267 63.3873 71.9261 62.4949L71.9251 62.4949Z"
                fill="white"
              />
              <path
                d="M18.3391 61.5794C21.1591 62.2804 24.0278 62.7704 26.919 63.0429C26.893 62.7996 26.893 62.5529 26.919 62.3096C27.8893 53.8692 32.0982 47.3562 39.4291 42.901C39.1922 42.914 38.9553 42.9269 38.7152 42.9269V42.9237C35.8985 42.9269 33.1693 41.9534 30.9886 40.1719C22.6065 44.41 18.0274 50.7738 17.0123 59.6228C16.9084 60.5152 17.4726 61.3457 18.3391 61.5794Z"
                fill="white"
              />
              <path
                d="M82.1083 61.5796C82.978 61.3492 83.5459 60.5153 83.442 59.6228C82.4295 50.7738 77.8475 44.4103 69.4657 40.1719C67.285 41.9534 64.5558 42.9269 61.7391 42.9237C61.499 42.9237 61.2621 42.9107 61.022 42.8977C68.3526 47.3565 72.5618 53.8627 73.5321 62.3063L73.5353 62.3096C73.5613 62.5529 73.5613 62.7996 73.5353 63.0429C76.4235 62.7703 79.2918 62.2805 82.1083 61.5796Z"
                fill="white"
              />
              <path
                d="M63.3334 75.3353C63.3334 76.3353 63.0534 77.2819 62.5601 78.082C61.6401 79.6286 59.9467 80.6686 58.0001 80.6686C56.6534 80.6686 55.4268 80.1753 54.4934 79.3353C54.0801 78.9886 53.7201 78.5619 53.4401 78.082C52.9468 77.2819 52.6667 76.3353 52.6667 75.3353C52.6667 72.3886 55.0534 70.002 58.0001 70.002C59.6001 70.002 61.0267 70.7086 62.0001 71.8153C62.8267 72.7619 63.3334 73.9886 63.3334 75.3353Z"
                fill="white"
                stroke="#EFEFEF"
                stroke-width="1.76363"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M55.92 75.333L57.24 76.653L60.08 74.0264"
                stroke="#EFEFEF"
                stroke-width="1.76363"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M62.0001 61.3346V71.8146C61.0268 70.7079 59.6001 70.0013 58.0001 70.0013C55.0535 70.0013 52.6668 72.388 52.6668 75.3346C52.6668 76.3346 52.9468 77.2813 53.4401 78.0813C53.7201 78.5613 54.0801 78.988 54.4935 79.3346H44.6668C40.0001 79.3346 38.0001 76.668 38.0001 72.668V61.3346C38.0001 57.3346 40.0001 54.668 44.6668 54.668H55.3335C60.0001 54.668 62.0001 57.3346 62.0001 61.3346Z"
                fill="white"
                stroke="#EFEFEF"
                stroke-width="1.76363"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M44.6666 52.667V56.667"
                stroke="#EFEFEF"
                stroke-width="1.76363"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M55.3335 52.667V56.667"
                stroke="#EFEFEF"
                stroke-width="1.76363"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M38.6669 62.1201H61.3335"
                stroke="#EFEFEF"
                stroke-width="1.76363"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M49.9939 68.2682H50.0059"
                stroke="#EFEFEF"
                stroke-width="2.35151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M45.0592 68.2682H45.0712"
                stroke="#EFEFEF"
                stroke-width="2.35151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M45.0592 72.2682H45.0712"
                stroke="#EFEFEF"
                stroke-width="2.35151"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <Typography
          className="text-gray-700 font-normal mt-2.5"
          size="lg"
          variant="p"
        >
          {/* Your team’s time off requests &<br></br> data will be represented here. */}
          Time off requests will be shown here.
        </Typography>
      </div>
    </div>
  );
};

export default OrganizationTimeOffEmpty;
