import React, { ChangeEvent } from 'react';

export type CheckboxProps = {
  label?: string;
  size?: string;
  checked: boolean;
  onCustomChange?: (checked: boolean) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onCustomChange, onChange, size, value }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    onChange?.(event);
    if (onCustomChange) {
      onCustomChange(newChecked);
    }
  };

  const handleLabelClick = () => {
    if (onChange) {
      const newChecked = !checked; // Toggle the checked state
      onChange({ target: { checked: newChecked } } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className="inline-block">
      <div className={`flex gap-3 items-center ${size === "sm" ? "text-sm !gap-2" : ""}`}>
        <label onClick={handleLabelClick}>
          <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            className={`
          relative appearance-none rounded border border-gray-100 peer bg-white
          checked:bg-secondary-100 checked:border-secondary-100
          after:content-[''] after:absolute after:inset-0 after:h-100 after:w-100 after:bg-check after:bg-no-repeat after:bg-center 
          ${size === "sm" ? "w-4 h-4 after:bg-[length:0.5rem_0.5rem]" : "w-5 h-5 after:bg-[length:0.75rem_0.75rem]"}
          `}
            value={value}
          />

        </label>
        {label ? <span className={`${size === "sm" ? "text-200 text-gray-900 whitespace-nowrap" : ""}`}>{label}</span> : null}
      </div>
    </div>
  );
};

export default Checkbox;
