import React, { ChangeEvent, useState, useEffect } from 'react';

export type TextareaProps = {
  placeholder: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  resize?: boolean;
  counter?: boolean;
  maxCharacters?: number;
};

const Textarea: React.FC<TextareaProps> = ({
  placeholder,
  disabled = false,
  value,
  onChange,
  resize = false,
  maxCharacters,
  counter
}) => {
  const [characterCount, setCharacterCount] = useState(value?.length || 0); 

  useEffect(() => {
    setCharacterCount(value?.length || 0);
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (maxCharacters && newValue.length > maxCharacters) {
      return;
    }
    onChange(newValue);
    setCharacterCount(newValue.length);
  };

  return (
    <div className={`relative `}>
      <textarea
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        className={`outline-none w-full bg-white border border-gray-100 px-4 py-3 text-400 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
        focus:border-secondary-100 focus:shadow-200 focus:rounded-md
        disabled:bg-gray-200 h-[5.5rem]
        ${resize ? 'resize-none' : ''}
        `}
        // ${counter ? "pb-8 " : ""}
      />
      {counter ? (
        <span className={`absolute right-2 -bottom-4 text-200 p-1`}>
          Count : {characterCount} / {maxCharacters}
        </span>
      ): null}
    </div>
  );
};

export default Textarea;
