import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { Dropdown } from "../../../components/DropDown/DropDown";
import { convertDateTime, formatDate, getDayAndMonth } from "../../../utils/common";
import Textarea from "../../../components/TextArea/Textarea";
import RadioButton from "../../../components/Radio button/RadioButton";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  approveTimeoffAsync,
  cancelTimeoffAsync,
  getDetailTimeoffAsync,
} from "../../../services/reducers/leaveReducer";
import EchopulseLoader from "../../loader/EchopulseLoader";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import AdminDeleteConfirmModal from "./AdminDeleteConfirmModal";
import { getSettingListAsync } from "../../../services/reducers/adminReducer";
import ColorLabel from "../ColorLable";

type Props = {
  onClose: () => void;
  timeOffApprove: boolean;
  timeoffId: string;
  orgnisationList: () => void;
  setTimeOffDetails: React.Dispatch<React.SetStateAction<boolean>>;
  timeOffCancel: boolean;
  type: string;
  userProfile: any;
  getSettingList: any;
};

const AdminTimeOffDetailsModal: React.FC<Props> = ({
  onClose,
  timeoffId,
  timeOffApprove,
  orgnisationList,
  setTimeOffDetails,
  timeOffCancel,
  type,
  userProfile,
  getSettingList,
}) => {
  const dispatch = useAppDispatch();
  const [timeDetail, setTimeDetail] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [updateNote, setUpdateNote] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (timeoffId) {
      dispatch(getDetailTimeoffAsync(timeoffId)).then((res) => {
        if (res.payload) {
          setTimeDetail(res.payload);
          setLoading(false);
        }
      });
    }
  }, [timeoffId]);

  const dayTypes = [
    { label: "Full", value: 0 },
    { label: "Half", value: 1 },
  ];

  const handleAction = (isApprove: boolean) => {
    if (timeoffId) {
      const reqBody: any = [
        {
          path: "/isAdminApproved",
          op: "replace",
          value: isApprove ? true : false,
        },
        {
          path: "/adminNote",
          op: "replace",
          value: updateNote,
        },
      ];

      dispatch(
        approveTimeoffAsync({
          id: timeoffId,
          isARM: type === "Admin & RM",
          reqBody,
        })
      ).then(() => {
        orgnisationList();
        toast.success(
          `Leave request ${isApprove ? "approved" : "denied"} successfully!`,
          TOASTIFY_CONF
        );
        setTimeOffDetails(false);
      });
    }
  };

  const handlecancelAction = () => {
    setDeleteConfirm(true);
  };

  return (
    <Modal isOpen onClose={onClose} placement="right" size="medium">
      <ModalHeader className="bg-gray-200 flex justify-start items-center gap-7">
        <Typography className="font-bold text-gray-300" variant="h3">
          Time off Details
        </Typography>
        {(timeDetail?.isAdminApproved === false &&
          timeDetail?.isRMApproved === false) ||
          (timeDetail?.isAdminApproved === true &&
            timeDetail?.isRMApproved === false) ||
          (timeDetail?.isAdminApproved === false &&
            timeDetail?.isRMApproved === null &&
            timeDetail?.isLeaveCancelled === false && (
              <div className="text-500 font-bold text-primary-100 relative flex justify-start items-center gap-1.5">
                <span className="h-[0.438rem] w-[0.438rem] rounded-full bg-primary-100 -mb-[0.063rem]"></span>
                Leave Request Denied
              </div>
            ))}
        {timeDetail?.isAdminApproved &&
          timeDetail?.isRMApproved &&
          timeOffCancel === false && (
            <div className="text-500 font-bold text-green-100 relative flex justify-start items-center gap-1.5">
              <span className="h-[0.438rem] w-[0.438rem] rounded-full bg-green-100 -mb-[0.063rem]"></span>
              Leave Request Approved
            </div>
          )}
        {timeOffCancel && (
          <div className="text-500 font-bold text-primary-100 relative flex justify-start items-center gap-1.5">
            <span className="h-[0.438rem] w-[0.438rem] rounded-full bg-primary-100 -mb-[0.063rem]"></span>
            Leave Request Cancelled
          </div>
        )}
      </ModalHeader>
      <ModalBody className="!px-0 !overflow-hidden">
        {loading ? (
          <tr>
            <td colSpan={3}>
              <EchopulseLoader />
            </td>
          </tr>
        ) : (
          <div className="flex justify-start items-start w-full h-full">
            <div className="w-[calc(100%-27.938rem)] h-full bg-white ps-20 pe-10 py-10 overflow-auto">
              <div className="w-full flex justify-between items-center">
                <div className="flex justify-start items-center gap-2.5">
                  <div>
                    {timeDetail?.myProfile?.profilePic ? (
                      <UserImage
                        imgKey={timeDetail?.myProfile.profilePic}
                        className="w-8 h-8 rounded-full object-cover"
                      />
                    ) : (
                      <img
                        src={getInitialsInName(
                          timeDetail?.myProfile?.name ?? "N A",
                          24
                        )}
                        alt={timeDetail?.myProfile?.name}
                        className="w-8 h-8 rounded-full object-cover"
                      />
                    )}
                  </div>
                  <div>
                    <div className="text-400 text-gray-300 font-medium">
                      {timeDetail?.myProfile?.name}
                    </div>
                    <div className="text-200 text-gray-300 font-normal line-clamp-1">
                      {timeDetail?.myProfile?.designation}
                      <span className="mx-1.5">|</span>
                      {timeDetail?.myProfile?.team}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-gray-300 font-extralight text-200 flex items-center">
                    <span className="text-600 font-bold ms-0.5">
                      {getDayAndMonth(timeDetail?.startDate).day}
                    </span>
                    {getDayAndMonth(timeDetail?.startDate).month}
                    <span className="mx-1">-</span>
                    <span className="text-600 font-bold ms-0.5">
                      {getDayAndMonth(timeDetail?.endDate).day}
                    </span>
                    {getDayAndMonth(timeDetail?.endDate).month}
                  </div>
                  {/* <div
                    className={`px-2.5 py-1 text-white text-100 font-normal rounded-full ${timeDetail?.isRMApproved === true ||
                        timeDetail?.isAdminApproved === true
                        ? "bg-green-100"
                        : "bg-pastel-900"
                      }`}
                  > */}
                  <ColorLabel text={timeDetail?.leave?.leaveCode === "RH" ? "Holiday" : timeDetail?.leave?.title} className="!h-auto !w-auto !text-100 !font-normal !px-2.5 !py-1 !rounded-2xl text-white">
                    {timeDetail?.leave?.title}{" "}
                    <span className="font-bold ms-1">
                      {timeDetail?.requestFor}d
                    </span>
                  </ColorLabel>
                  {/* </div> */}
                </div>
              </div>
              <div className="[&>div>textarea]:h-[6.625rem] mt-8 pb-4 border-b border-gray-100">
                <label className="text-400 font-medium text-gray-300 block mb-2">
                  Note from {timeDetail?.myProfile?.name}
                </label>
                <Textarea
                  placeholder="Add your note1"
                  onChange={function noRefCheck() { }}
                  disabled
                  resize
                  value={
                    timeDetail?.employeeNote ? timeDetail?.employeeNote : "-"
                  }
                />
                {timeDetail.employeeActionTaken && <span className="text-200 text-gray-900">Created on {convertDateTime(timeDetail.employeeActionTaken)}</span>}
              </div>
              <div className="[&>div>textarea]:h-[6.625rem] mt-7 pb-4 border-b border-gray-100">
                <div className="flex justify-between items-center mb-2">
                  <label className="text-400 font-medium text-gray-300 block">
                    Status from Manager : {timeDetail?.rmName}
                  </label>
                  {timeDetail?.isRMApproved === true && (
                    <div className="flex justify-start items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize py-1 px-3 rounded-full">
                      <span className="me-1">
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.56763 12.6872C9.86763 12.6872 12.5676 9.9872 12.5676 6.68719C12.5676 3.38719 9.86763 0.687195 6.56763 0.687195C3.26763 0.687195 0.567627 3.38719 0.567627 6.68719C0.567627 9.9872 3.26763 12.6872 6.56763 12.6872Z"
                            fill="#2EB67D"
                          />
                          <path
                            d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                            fill="#2EB67D"
                          />
                          <path
                            d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Approved
                    </div>
                  )}
                  {timeDetail?.isRMApproved === false && (
                    <div className="flex justify-start items-center text-200 capitalize font-normal text-primary-100 py-1 px-2.5 rounded-full bg-primary-100 bg-opacity-10">
                      <span className="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                            fill="#F15845"
                          />
                        </svg>
                      </span>
                      Denied
                    </div>
                  )}
                  {timeDetail?.isRMApproved === null && (
                    <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                      <span className="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                            fill="#888888"
                          />
                        </svg>
                      </span>
                      Pending
                    </div>
                  )}
                </div>
                <Textarea
                  placeholder="Add your note5"
                  onChange={function noRefCheck() { }}
                  disabled
                  resize
                  value={timeDetail?.rmNote ? timeDetail?.rmNote : "-"}
                />
                {timeDetail?.rmActionTaken && (
                  <span className="text-200 text-gray-900">
                    {timeDetail?.isRMApproved === true
                      ? `Approved on ${convertDateTime(timeDetail.rmActionTaken)}`
                      : timeDetail?.isRMApproved === false
                        ? `Denied on ${convertDateTime(timeDetail.rmActionTaken)}`
                        : timeDetail?.isRMApproved === null
                          ? `Pending on ${convertDateTime(timeDetail.rmActionTaken)}`
                          : ""}
                  </span>
                )}
              </div>
              <div className="[&>div>textarea]:h-[6.625rem] mt-7 pb-4 border-b border-gray-100">
                <div className="flex justify-between items-center mb-2">
                  <label className="text-400 font-medium text-gray-300 block">
                    Status from Administrator : {timeDetail?.adminName}
                  </label>
                  {timeDetail?.isLeaveCancelled === true ? (
                    <div className="flex justify-start items-center text-200 text-secondary-300 bg-pastel-600 capitalize py-1 px-3 rounded-full">
                      <span className="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                            fill="#ECB22E"
                          />
                        </svg>
                      </span>
                      Cancelled
                    </div>
                  ) : timeDetail?.isAdminApproved === true ? (
                    <div className="flex justify-start items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize py-1 px-3 rounded-full">
                      <span className="me-1">
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.56763 12.6872C9.86763 12.6872 12.5676 9.9872 12.5676 6.68719C12.5676 3.38719 9.86763 0.687195 6.56763 0.687195C3.26763 0.687195 0.567627 3.38719 0.567627 6.68719C0.567627 9.9872 3.26763 12.6872 6.56763 12.6872Z"
                            fill="#2EB67D"
                          />
                          <path
                            d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                            fill="#2EB67D"
                          />
                          <path
                            d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      Approved
                    </div>
                  ) : timeDetail?.isAdminApproved === null ? (
                    <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                      <span className="me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                            fill="#888888"
                          />
                        </svg>
                      </span>
                      Pending
                    </div>
                  ) : (
                    timeDetail?.isAdminApproved === false && (
                      <div className="flex justify-start items-center text-200 capitalize font-normal text-primary-100 py-1 px-2.5 rounded-full bg-primary-100 bg-opacity-10">
                        <span className="me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                          >
                            <path
                              d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                              fill="#F15845"
                            />
                          </svg>
                        </span>
                        Denied
                      </div>
                    )
                  )}
                </div>
                <Textarea
                  placeholder="Add your note2"
                  onChange={function noRefCheck() { }}
                  disabled
                  resize
                  value={
                    timeDetail?.adminNote ? timeDetail?.adminNote : "-"
                  }
                />
                {timeDetail?.adminActionTaken && (
                  <span className="text-200 text-gray-900">
                    {timeDetail?.isLeaveCancelled === true
                      ? `Cancelled on ${convertDateTime(timeDetail.adminActionTaken)}`
                      : timeDetail?.isAdminApproved === true
                        ? `Approved on ${convertDateTime(timeDetail.adminActionTaken)}`
                        : timeDetail?.isAdminApproved === false
                          ? `Denied on ${convertDateTime(timeDetail.adminActionTaken)}`
                          : timeDetail?.isAdminApproved === null
                            ? `Pending on ${convertDateTime(timeDetail.adminActionTaken)}`
                            : ""}
                  </span>
                )}
              </div>
              {/* {getSettingList[0]?.timeOffApproval === 1 && (
                <div className="[&>div>textarea]:h-[6.625rem] mt-6 pb-4 border-b border-gray-100">
                  <div className="flex justify-between items-center mb-2">
                    <label className="text-400 font-medium text-gray-300 block">
                      Status from Manager : {timeDetail?.rmName}
                    </label>
                   
                    {timeDetail?.isRMApproved === true ? (
                      <div className="flex justify-start items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize py-1 px-3 rounded-full">
                        <span className="me-1">
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.56763 12.6872C9.86763 12.6872 12.5676 9.9872 12.5676 6.68719C12.5676 3.38719 9.86763 0.687195 6.56763 0.687195C3.26763 0.687195 0.567627 3.38719 0.567627 6.68719C0.567627 9.9872 3.26763 12.6872 6.56763 12.6872Z"
                              fill="#2EB67D"
                            />
                            <path
                              d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                              fill="#2EB67D"
                            />
                            <path
                              d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        Approved
                      </div>
                    ) : timeDetail?.isRMApproved === null ? (
                      <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                        <span className="me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                              fill="#888888"
                            />
                          </svg>
                        </span>
                        Pending
                      </div>
                    ) : (
                      timeDetail?.isRMApproved === false && (
                        <div className="flex justify-start items-center text-200 capitalize font-normal text-primary-100 py-1 px-2.5 rounded-full bg-primary-100 bg-opacity-10">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                            >
                              <path
                                d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                                fill="#F15845"
                              />
                            </svg>
                          </span>
                          Denied
                        </div>
                      )
                    )}
                  </div>
                  <Textarea
                    placeholder="Add your note3"
                    onChange={function noRefCheck() {}}
                    disabled
                    resize
                    value={timeDetail?.rmNote ? timeDetail?.rmNote : "-"}
                  />
                </div>
              )}
              {getSettingList[0]?.timeOffApproval === 0 &&
                timeDetail?.isAdminApproved !== null && (
                  <div className="[&>div>textarea]:h-[6.625rem] mt-7 pb-4 border-b border-gray-100">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-400 font-medium text-gray-300 block">
                      Status from Administrator : {timeDetail?.adminName}
                      </label>
                      {timeDetail?.isLeaveCancelled === true ? (
                        <div className="flex justify-start items-center text-200 text-secondary-300 bg-pastel-600 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                                fill="#ECB22E"
                              />
                            </svg>
                          </span>
                          Cancelled
                        </div>
                      ) : timeDetail?.isAdminApproved === true ? (
                        <div className="flex justify-start items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.56763 12.6872C9.86763 12.6872 12.5676 9.9872 12.5676 6.68719C12.5676 3.38719 9.86763 0.687195 6.56763 0.687195C3.26763 0.687195 0.567627 3.38719 0.567627 6.68719C0.567627 9.9872 3.26763 12.6872 6.56763 12.6872Z"
                                fill="#2EB67D"
                              />
                              <path
                                d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                                fill="#2EB67D"
                              />
                              <path
                                d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Approved
                        </div>
                      ) : timeDetail?.isAdminApproved === null ? (
                        <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                                fill="#888888"
                              />
                            </svg>
                          </span>
                          Pending
                        </div>
                      ) : (
                        timeDetail?.isAdminApproved === false && (
                          <div className="flex justify-start items-center text-200 capitalize font-normal text-primary-100 py-1 px-2.5 rounded-full bg-primary-100 bg-opacity-10">
                            <span className="me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                fill="none"
                              >
                                <path
                                  d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                                  fill="#F15845"
                                />
                              </svg>
                            </span>
                            Denied
                          </div>
                        )
                      )}
                    </div>
                    <Textarea
                      placeholder="Add your note4"
                      onChange={function noRefCheck() {}}
                      disabled
                      resize
                      value={
                        timeDetail?.adminNote ? timeDetail?.adminNote : "-"
                      }
                    />
                  </div>
                )}
              {getSettingList[0]?.timeOffApproval === 1 &&
                timeDetail?.isLeaveCancelled === true && (
                  <div className="[&>div>textarea]:h-[6.625rem] mt-6 pb-4 border-b border-gray-100">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-400 font-medium text-gray-300 block">
                      Status from Administrator : {timeDetail?.adminName}
                      </label>

                      <div className="flex justify-start items-center text-200 text-secondary-300 bg-pastel-600 capitalize py-1 px-3 rounded-full">
                        <span className="me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                              fill="#ECB22E"
                            />
                          </svg>
                        </span>
                        Cancelled
                      </div>
                    </div>
                    <Textarea
                      placeholder="Add your note4"
                      onChange={function noRefCheck() {}}
                      disabled
                      resize
                      value={
                        timeDetail?.adminNote ? timeDetail?.adminNote : "-"
                      }
                    />
                  </div>
                )}
              {getSettingList[0]?.timeOffApproval === 2 && (
                <>
                  <div className="[&>div>textarea]:h-[6.625rem] mt-7 pb-4 border-b border-gray-100">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-400 font-medium text-gray-300 block">
                      Status from Manager :{timeDetail?.rmName}
                      </label>
                      {timeDetail?.isRMApproved === true && (
                        <div className="flex justify-start items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.56763 12.6872C9.86763 12.6872 12.5676 9.9872 12.5676 6.68719C12.5676 3.38719 9.86763 0.687195 6.56763 0.687195C3.26763 0.687195 0.567627 3.38719 0.567627 6.68719C0.567627 9.9872 3.26763 12.6872 6.56763 12.6872Z"
                                fill="#2EB67D"
                              />
                              <path
                                d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                                fill="#2EB67D"
                              />
                              <path
                                d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Approved
                        </div>
                      )}
                      {timeDetail?.isRMApproved === false && (
                        <div className="flex justify-start items-center text-200 capitalize font-normal text-primary-100 py-1 px-2.5 rounded-full bg-primary-100 bg-opacity-10">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                            >
                              <path
                                d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                                fill="#F15845"
                              />
                            </svg>
                          </span>
                          Denied
                        </div>
                      )}
                      {timeDetail?.isRMApproved === null && (
                        <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                                fill="#888888"
                              />
                            </svg>
                          </span>
                          Pending
                        </div>
                      )}
                    </div>
                    <Textarea
                      placeholder="Add your note5"
                      onChange={function noRefCheck() {}}
                      disabled
                      resize
                      value={timeDetail?.rmNote ? timeDetail?.rmNote : "-"}
                    />
                  </div>
                  <div className="[&>div>textarea]:h-[6.625rem] mt-7 pb-4 border-b border-gray-100">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-400 font-medium text-gray-300 block">
                      Status from Administrator: {timeDetail?.adminName}
                      </label>
                      {timeDetail?.isLeaveCancelled === true ? (
                        <div className="flex justify-start items-center text-200 text-secondary-300 bg-pastel-600 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8 0.5C3.8579 0.5 0.5 3.8579 0.5 8C0.5 12.1421 3.8579 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.8579 12.1421 0.5 8 0.5ZM11.7961 10.2107C12.2339 10.6485 12.2339 11.3584 11.7961 11.7961C11.3582 12.2339 10.6484 12.2339 10.2107 11.7961L8 9.58539L5.7893 11.7961C5.35145 12.2339 4.64158 12.2339 4.20391 11.7961C3.76607 11.3582 3.76607 10.6484 4.20391 10.2107L6.41461 8L4.20391 5.7893C3.76607 5.35145 3.76607 4.64158 4.20391 4.20391C4.64175 3.76607 5.35163 3.76607 5.7893 4.20391L8 6.41461L10.2107 4.20391C10.6485 3.76607 11.3584 3.76607 11.7961 4.20391C12.2339 4.64175 12.2339 5.35163 11.7961 5.7893L9.58539 8L11.7961 10.2107Z"
                                fill="#ECB22E"
                              />
                            </svg>
                          </span>
                          Cancelled
                        </div>
                      ) : timeDetail?.isAdminApproved === true ? (
                        <div className="flex justify-start items-center text-200 text-green-100 bg-green-100 bg-opacity-10 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.56763 12.6872C9.86763 12.6872 12.5676 9.9872 12.5676 6.68719C12.5676 3.38719 9.86763 0.687195 6.56763 0.687195C3.26763 0.687195 0.567627 3.38719 0.567627 6.68719C0.567627 9.9872 3.26763 12.6872 6.56763 12.6872Z"
                                fill="#2EB67D"
                              />
                              <path
                                d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                                fill="#2EB67D"
                              />
                              <path
                                d="M4.01782 6.68726L5.71582 8.38526L9.11782 4.98926"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          Approved
                        </div>
                      ) : timeDetail?.isAdminApproved === null ? (
                        <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                                fill="#888888"
                              />
                            </svg>
                          </span>
                          Pending
                        </div>
                      ) : (
                        timeDetail?.isAdminApproved === false && (
                          <div className="flex justify-start items-center text-200 capitalize font-normal text-primary-100 py-1 px-2.5 rounded-full bg-primary-100 bg-opacity-10">
                            <span className="me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                fill="none"
                              >
                                <path
                                  d="M7 0.5C10.866 0.5 14 3.8579 14 8C14 12.1421 10.866 15.5 7 15.5C3.13404 15.5 0 12.1421 0 8C0 3.8579 3.13404 0.5 7 0.5ZM3.45698 10.2107C3.04833 10.6485 3.04833 11.3584 3.45698 11.7961C3.86564 12.2339 4.52819 12.2339 4.93668 11.7961L7 9.58539L9.06332 11.7961C9.47198 12.2339 10.1345 12.2339 10.543 11.7961C10.9517 11.3582 10.9517 10.6484 10.543 10.2107L8.47969 8L10.543 5.7893C10.9517 5.35145 10.9517 4.64158 10.543 4.20391C10.1344 3.76607 9.47182 3.76607 9.06332 4.20391L7 6.41461L4.93668 4.20391C4.52802 3.76607 3.86548 3.76607 3.45698 4.20391C3.04833 4.64175 3.04833 5.35163 3.45698 5.7893L5.52031 8L3.45698 10.2107Z"
                                  fill="#F15845"
                                />
                              </svg>
                            </span>
                            Denied
                          </div>
                        )
                      )}
                    </div>
                    <Textarea
                      placeholder="Add your note2"
                      onChange={function noRefCheck() {}}
                      disabled
                      resize
                      value={
                        timeDetail?.adminNote ? timeDetail?.adminNote : "-"
                      }
                    />
                  </div>
                </>
              )} */}
              {/* {timeDetail?.isLeaveCancelled === false && ( */}
              {((timeDetail?.myProfile?.identityId !== userProfile?.identityId &&
                timeDetail?.isLeaveCancelled === false &&
                timeDetail?.isAdminApproved !== false &&
                timeDetail?.isRMApproved !== false) || (getSettingList[0]?.timeOffApproval === 2 && timeDetail?.isRMApproved === false && (timeDetail?.isAdminApproved === true || timeDetail?.isAdminApproved === null) && timeDetail?.isLeaveCancelled === false)) && (
                  <div className="[&>div>textarea]:h-[6.625rem] mt-7">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-400 font-medium text-gray-300 block">
                        Add your note
                        {/* <span className="text-primary-100 ps-0.5">*</span> */}
                      </label>
                      {/* <div className="flex justify-start items-center text-200 text-gray-700 bg-gray-100 capitalize py-1 px-3 rounded-full">
                  <span className="me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M15.9949 13.8477C15.9742 13.6152 15.8932 13.3733 15.7516 13.1287L9.14024 1.67727C8.99942 1.4326 8.83087 1.2413 8.63955 1.10687C8.44183 0.968898 8.22063 0.896362 8.00016 0.896362C7.77969 0.896362 7.55849 0.968907 7.36078 1.10687C7.16946 1.24058 7.0009 1.43262 6.86009 1.67727L0.247964 13.1287C0.107143 13.3733 0.0253453 13.6152 0.00473477 13.8477C-0.0158903 14.0874 0.0310496 14.3157 0.142001 14.507C0.25224 14.6983 0.425778 14.8534 0.644118 14.9551C0.855358 15.0539 1.10571 15.1037 1.38805 15.1037H14.611C14.8934 15.1037 15.1437 15.0539 15.3549 14.9551C15.5733 14.8534 15.7468 14.6983 15.8571 14.507C15.9687 14.3157 16.0162 14.0874 15.9949 13.8477ZM8.00009 13.0405C7.55558 13.0405 7.19499 12.6799 7.19499 12.2354C7.19499 11.7909 7.55558 11.431 8.00009 11.431C8.4446 11.431 8.80519 11.7909 8.80519 12.2354C8.80519 12.6799 8.4446 13.0405 8.00009 13.0405ZM8.96735 5.87286L8.70917 10.1359C8.69423 10.3919 8.47091 10.6017 8.21415 10.6017H7.78457C7.52781 10.6017 7.30521 10.3919 7.28956 10.1359L7.03138 5.87286C7.01573 5.61683 7.21274 5.40701 7.46948 5.40701H8.52706C8.78524 5.40772 8.98299 5.61681 8.96735 5.87286Z"
                        fill="#888888"
                      />
                    </svg>
                  </span>
                  Pending
                </div> */}
                    </div>
                    <Textarea
                      placeholder="Write here"
                      onChange={(value: string) => setUpdateNote(value)}
                      resize
                      value={updateNote}
                      disabled={
                        timeDetail?.isRMApproved === null &&
                        timeDetail?.isAdminApproved === null &&
                        getSettingList[0]?.timeOffApproval === 1
                      }
                    />
                  </div>
                )}
            </div>
            <div className="w-[27.938rem] h-full bg-gray-200 overflow-auto">
              <div className="py-10 ps-10 pe-12">
                <Typography variant="h6" className="font-bold text-gray-300">
                  Details
                </Typography>
                <Typography
                  size="lg"
                  variant="p"
                  className="text-gray-700 font-medium mt-2.5"
                >
                  Selected Dates
                </Typography>
                {timeDetail?.details?.map((t1: any, index: number) => (
                  <>
                    <div className="flex justify-start items-center gap-4 mt-5">
                      <div className="text-500 text-gray-300 font-normal">
                        {format(t1?.timeOffDate, "dd MMM")}
                      </div>
                      <div className="w-[6.375rem]">
                        <Dropdown
                          onChange={function noRefCheck() { }}
                          options={dayTypes}
                          type="mdbox"
                          isSearchable={false}
                          isDisabled
                          value={dayTypes.find(
                            (option) => option.value === t1?.dayType
                          )}
                        />
                      </div>
                      <div>day</div>
                    </div>
                    {t1?.dayType === 1 && (
                      <div className="mt-5">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Select Half day period
                          <span className="text-primary-100 ps-0.5">*</span>
                        </label>
                        <div className="flex justify-start items-center gap-6">
                          <RadioButton
                            label="First Half"
                            name={`details[${index}].halfDayType`}
                            onChange={function noRefCheck() { }}
                            selectedValue={`${t1?.halfDayType}`}
                            disabled
                            value="0"
                          />
                          <RadioButton
                            label="Second Half"
                            name={`details[${index}].halfDayType`}
                            onChange={function noRefCheck() { }}
                            selectedValue={`${t1?.halfDayType}`}
                            disabled
                            value="1"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ))}
                <div className="text-600 text-gray-300 font-bold mt-12">
                  Requesting : {timeDetail?.requestFor}{" "}
                  {timeDetail?.requestFor !== null && timeDetail?.requestFor > 1
                    ? "Days"
                    : "Day"}
                </div>
                {!(timeDetail?.leave?.leaveCode === "RH" || timeDetail?.leave?.leaveCode === "LOP" || timeDetail?.leave?.leaveCode === "COFF") && (
                  <div className="text-500 text-gray-300 font-normal mt-4">
                    Leave Balance: {timeDetail?.leaveBalance}{" "}
                    {timeDetail?.leaveBalance !== null &&
                      timeDetail?.leaveBalance > 1
                      ? "Days"
                      : "Day"}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-start gap-5">
          {timeDetail?.myProfile?.identityId === userProfile?.identityId ? (
            <Button
              label="Okay"
              onClick={onClose}
              variant="primary"
              size="large"
            />
          ) : timeOffApprove === null &&
            getSettingList[0]?.timeOffApproval !== 1 ? (
            <>
              <Button
                label="Approve"
                onClick={() => handleAction(true)}
                variant="primary"
                size="large"
              />
              <button
                className="text-500 text-secondary-100 font-bold hover:text-secondary-200"
                onClick={() => handleAction(false)}
              >
                Deny
              </button>
            </>
          ) : (
            <>
              <Button
                label="Okay"
                onClick={onClose}
                variant="primary"
                size="large"
              />
              {(timeDetail?.isAdminApproved === true ||
                timeDetail?.isRMApproved === true ||
                (timeDetail?.isAdminApproved === true &&
                  timeDetail?.isRMApproved === null)) &&
                timeOffCancel === false && !(timeDetail?.isRMApproved === true && timeDetail?.isAdminApproved === false) && (
                  <Button
                    label="Cancel Request"
                    onClick={handlecancelAction}
                    variant="outline"
                    size="large"
                  />
                )}
            </>
          )}
        </div>
      </ModalFooter>
      {deleteConfirm && (
        <AdminDeleteConfirmModal
          onClose={() => setDeleteConfirm(false)}
          timeOffId={timeoffId}
          setDeleteConfirm={setDeleteConfirm}
          orgnisationList={orgnisationList}
          setTimeOffDetails={setTimeOffDetails}
          updateNote={updateNote}
        />
      )}
    </Modal>
  );
};

export default AdminTimeOffDetailsModal;
