import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useAppDispatch } from "../../../hooks";
import { deleteTimeOffAsync } from "../../../services/reducers/leaveReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";

type Props = {
  onClose: () => void;
  timeOffId: string;
  getTimeoffList: () => void;
  setDeleteTimeOff: React.Dispatch<React.SetStateAction<boolean>>;
  deleteTimeOffUsed: boolean;
};
const AdminDeleteTimeOffConfirmModal: React.FC<Props> = ({
  onClose,
  timeOffId,
  getTimeoffList,
  setDeleteTimeOff,
  deleteTimeOffUsed,
}) => {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    if (timeOffId) {
      dispatch(deleteTimeOffAsync(timeOffId)).then((res) => {
        getTimeoffList();
        //toast.success("TimeOff Deleted Successfully!", TOASTIFY_CONF);
        setDeleteTimeOff(false);
      });
    }
  };
  return (
    <Modal
      isOpen
      onClose={onClose}
      size="normal"
      placement="center"
      className="z-[9999]"
      showCloseButton={!deleteTimeOffUsed}
    >
      <ModalBody className="!ps-10 !py-10 !pe-16">
        <div className="pb-3">
          {deleteTimeOffUsed ? (
            <>
              <Typography variant="h3" className="font-bold text-primary-100">
                ERROR!
              </Typography>
              <Typography
                className="text-gray-700 font-normal mt-2"
                size="xxl"
                variant="p"
              >
                The selected leave type is in use. It cannot be deleted
              </Typography>
            </>
          ) : (
            <div className="pe-10">
              <Typography variant="h3" className="font-bold text-gray-300">
                Are you sure you want to Cancel this Time Off Type?
              </Typography>
              <Typography
                className="text-gray-700 font-normal mt-2"
                size="xxl"
                variant="p"
              >
                Members in your organization will not be able to view & use this
                time off type after deleting it.
              </Typography>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="bg-gray-200 pe-10">
        <div className="w-full flex justify-end items-end gap-3">
          <Button
            label={deleteTimeOffUsed ? "Okay" : "Cancel"}
            onClick={onClose}
            variant={deleteTimeOffUsed ? "secondary" : "outline"}
            size="large"
          />
          {deleteTimeOffUsed === false && (
            <Button
              label="Yes, I am sure"
              onClick={() => handleDelete()}
              variant="primary"
              size="large"
            />
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AdminDeleteTimeOffConfirmModal;
