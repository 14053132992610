import { API_URL } from "../sharedService/constants";
import { http } from "../sharedService/httpService";

export function getJiraTask(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/ExternalApp/GetAllTasks?ProjectKey=${reqBody.ProjectKey}&TaskKey=${reqBody.TaskKey}`
  );
}

export function getJiraProject() {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/ExternalApp/GetAllProjects`
  );
}

export function getRecentTask(reqBody: any) {
  var nevUrl;
  if (reqBody.MemberId === undefined || reqBody.MemberId === "") {
    nevUrl = `api/TimeSheet/GetRecentTasks?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}`;
  } else {
    nevUrl = `api/TimeSheet/GetRecentTasks?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}&MemberId=${reqBody.MemberId}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getRecentProject(reqBody: any) {
  var nevUrl;
  if (reqBody.MemberId === undefined || reqBody.MemberId === "") {
    nevUrl = `api/TimeSheet/GetRecentProjects?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}`;
  } else {
    nevUrl = `api/TimeSheet/GetRecentProjects?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}&MemberId=${reqBody.MemberId}`;
  }
  return http.get(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getList(reqBody: any) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/List?StartDate=${reqBody.StartDate}&EndDate=${reqBody.EndDate}&PageSize=${reqBody.PageSize}&ViewType=${reqBody.ViewType}&Page=${reqBody.Page}`
  );
}

export function createTimesheet(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/Create`,
    reqBody
  );
}

export function deleteTimesheet(reqBody: any) {
  return http.delete(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet?Identifier=${reqBody.Identifier}&WeekStartDate=${reqBody.WeekStartDate}&WeekEndDate=${reqBody.WeekEndDate}&MemberId=${reqBody.MemberId}&ProjectId=${reqBody.ProjectId}`
  );
}
export function updateTimesheet(reqBody: any) {
  return http.put(`${API_URL.INTEGRATION_API_URL}api/TimeSheet`, reqBody);
}

export function createTimeCard() {
  return http.post(`${API_URL.INTEGRATION_API_URL}api/TimeCard/Create`);
}

export function getTimecardList() {
  return http.get(`${API_URL.INTEGRATION_API_URL}api/TimeCard/List`);
}

export function updateTimeCard(id: string, reqBody: any) {
  return http.put(`${API_URL.INTEGRATION_API_URL}api/TimeCard/${id}`, reqBody);
}

export function getNoteList(timeSheetId: string) {
  return http.get(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/GetLogedNotes/${timeSheetId}`
  );
}

export function updateNote(reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/UpdateNote`,
    reqBody
  );
}

export function removeNote(noteId: string) {
  return http.delete(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/RemoveNote/${noteId}`
  );
}

export function timeSheetSubmission(reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/TimeSheetSubmission`,
    reqBody
  );
}

export function createEmployeeTimesheet(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}api/TimeSheet/EmployeeAddTaskRequest`,
    reqBody
  );
}

export function updateEmployeeTimesheet(reqBody: any) {
  return http.put(`${API_URL.INTEGRATION_API_URL}api/TimeSheet/EmployeeUpdateTask`, reqBody);
}
