"use client";
import React, { ReactNode } from "react";

interface AccordionProps {
  title: string;
  iconLeft: ReactNode;
  isOpen: boolean;
  onClick: () => void;
  children: ReactNode;
  isFilterExpanded: boolean;
}

const FilterAccordion: React.FC<AccordionProps> = ({
  title,
  iconLeft,
  isOpen,
  onClick,
  children,
  isFilterExpanded,
}) => {
  return (
    <div className="border-b border-gray-100 pb-3 mb-3">
      <div
        className="flex justify-between items-center cursor-pointer transition-all duration-300 ease-in-out"
        onClick={onClick}
      >
        <div className="flex items-center h-6">
          <span className="flex ms-0.5">{iconLeft}</span>
          <h2
            className={`h-4 ms-2 text-200 text-gray-300 font-medium whitespace-nowrap ${
              isFilterExpanded ? "visible" : "invisible group-hover:visible"
            }`}
          >
            {title}
          </h2>
        </div>
        <span
          className={` ${
            isFilterExpanded ? "visible" : "invisible group-hover:visible"
          }`}
        >
          {isOpen ? (
            <svg
              className="w-3.5 h-3.5 transform rotate-90 transition-transform duration-300 ease-in-out"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.23123 2.25105C6.92292 2.58579 6.92292 3.1285 7.23123 3.46323L15.094 12L7.23123 20.5368C6.92292 20.8715 6.92292 21.4142 7.23123 21.749C7.53954 22.0837 8.03941 22.0837 8.34772 21.749L16.7688 12.6061C16.9168 12.4453 17 12.2273 17 12C17 11.7727 16.9168 11.5547 16.7688 11.3939L8.34772 2.25105C8.03941 1.91632 7.53954 1.91632 7.23123 2.25105Z"
                fill="#888888"
              />
            </svg>
          ) : (
            <svg
              className="w-4 h-4 transition-transform duration-300 ease-in-out"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.23123 2.25105C6.92292 2.58579 6.92292 3.1285 7.23123 3.46323L15.094 12L7.23123 20.5368C6.92292 20.8715 6.92292 21.4142 7.23123 21.749C7.53954 22.0837 8.03941 22.0837 8.34772 21.749L16.7688 12.6061C16.9168 12.4453 17 12.2273 17 12C17 11.7727 16.9168 11.5547 16.7688 11.3939L8.34772 2.25105C8.03941 1.91632 7.53954 1.91632 7.23123 2.25105Z"
                fill="#888888"
              />
            </svg>
          )}
        </span>
      </div>
      {isOpen && (
        <div
          className={`transition-transform duration-300 ease-in-out ${
            isFilterExpanded
              ? "inline-block"
              : "hidden group-hover:inline-block"
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default FilterAccordion;
